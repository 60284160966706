import React, { useState, useEffect } from "react";
import { Grid, OutlinedInput, makeStyles, IconButton } from "@material-ui/core";
import { validateNumber } from "../utils/util";
import { MinusIcon, PlusStandardIcon } from "./SVG";

const useStyles = makeStyles({
  noBorder: {
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  roundedInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "3px", // Adjust the value to make the corners more or less rounded
      border: "1px solid #051D33",
    },
  },
});
const ModifierCounter = ({
  initialCount = 1,
  onChange,
  max = 10,
  min = 1,
  // isAllowMaxSelect = false,
  multiSelectMax,
  ModifierCounterDisable = false,
}) => {
  const classes = useStyles();
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const handleChange = (newCount) => {
    const validCount = Math.max(min, Math.min(newCount, getMaxLimit()));
    setCount(validCount);
    onChange(validCount);
  };

  const getMaxLimit = () => {
    if (!ModifierCounterDisable) {
      return Math.min(multiSelectMax || max, max);
    }
    return max;
  };

  const decrement = () => {
    if (count > min) {
      handleChange(count - 1);
    }
  };

  const increment = () => {
    const maxLimit = getMaxLimit();
    if (count < maxLimit) {
      handleChange(count + 1);
    }
  };

  return (
    <Grid
      container
      direction='row'
      style={{
        height: 35,
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        disabled: ModifierCounterDisable,
        foregroundColor: ModifierCounterDisable ? "#D3D3D3" : "initial", // Grey out when disabled
        // backgroundColor: ModifierCounterDisable ? "#e0e0e0" : "initial", // Grey out when disabled
        pointerEvents: ModifierCounterDisable ? "none" : "auto", // Disable click events when disabled
        opacity: ModifierCounterDisable ? 0.5 : 1, // Reduce opacity when disabled
      }}>
      <Grid
        item
        xs={2.5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: ModifierCounterDisable ? "not-allowed" : "pointer", // Change cursor when disabled
        }}>
        <IconButton
          onClick={decrement}
          style={{ display: "flex" }}
          disabled={ModifierCounterDisable}>
          <MinusIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={7}
        style={{
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <OutlinedInput
          classes={{
            notchedOutline: classes.roundedInput,
          }}
          style={{
            height: "100%",
            display: "flex",
          }}
          variant='standard'
          value={count}
          onChange={(e) => {
            const newValue = validateNumber(e.target.value);
            handleChange(newValue);
          }}
          inputProps={{
            style: {
              textAlign: "center",
              padding: "0",
              width: "100%",
              height: "100%",
              border: "none",
              fontSize: "15px",
              fontWeight: "bold",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={2.5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: ModifierCounterDisable ? "not-allowed" : "pointer", // Change cursor when disabled
        }}>
        <IconButton
          onClick={increment}
          style={{ display: "flex" }}
          disabled={ModifierCounterDisable}>
          <PlusStandardIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ModifierCounter;
