import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import packageInfo from "../../package.json";

const useStyles = makeStyles(() => ({
  footer: {
    paddingTop: 10,
    paddingBottom: 10,
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 1200,
    backgroundColor: "#EEE",
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #051D33",
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    color: "rgb(5, 29, 51)",
    marginRight: 60,
    fontFamily: "AvenirLT-Medium",
  },
  linkButton: {
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 400,
    textTransform: "none",
    color: "#004C60",
    fontFamily: "AvenirLT-Medium",
  },
}));

const Footer = ({ from }) => {
  const classes = useStyles();

  const link = useMemo(() => {
    // eslint-disable-next-line no-undef
    const loc = window.location.origin;
    if (packageInfo.routepath)
      return `${loc}/${packageInfo.routepath}/terms_of_use`;
    return `${loc}/terms_of_use`;
  }, [packageInfo.routepath]);

  const year = new Date().getFullYear();

  return (
    <Box component='footer' className={classes.footer}>
      <Typography variant='body2' className={classes.text}>
        Copyright Moneris Go Restaurant POS {year}
      </Typography>
      <FooterLink
        title='Moneris.com'
        link='https://www.moneris.com'
        from={from}
      />
      <FooterLink title='Terms of use' link={link} from={from} />
      <FooterLink
        title='Privacy notice'
        link='https://www.moneris.com/en/legal/privacy-notice'
        from={from}
      />
      <FooterLink
        title='Support'
        link='https://www.moneris.com/en/support/moneris-go/moneris-go-app%C3%A9tit'
        from={from}
      />
    </Box>
  );
};

const FooterLink = ({ title, link, isOpenNewTab = true, from }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClick = () => {
    navigate(from === "main" ? "/terms" : "/home/terms");
  };

  return isOpenNewTab ? (
    <Button
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className={classes.linkButton}>
      {title}
    </Button>
  ) : (
    <Button onClick={handleClick} className={classes.linkButton}>
      {title}
    </Button>
  );
};

export default Footer;
