import React from "react";
import { Grid } from "@material-ui/core";

const MainBodyWrapper = ({ children }) => (
  <Grid
    data-qaid='item_catalogue_grid'
    container
    style={{
      padding: 40,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
    }}>
    <Grid> {children}</Grid>
  </Grid>
);

export default MainBodyWrapper;
