import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Backdrop } from "@material-ui/core";
import { useDispatch } from "react-redux";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    marginTop: 64,
    height: "calc(100% - 64px)",
    position: "relative",
  },
  drawerAboveTabSize: {
    width: 200,
    marginTop: 64,
  },
  drawerOpen: {
    width: drawerWidth,
    marginTop: 64,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: 64,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 80,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    position: "absolute",
    zIndex: 100,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  arrow: {
    position: "fixed",
    top: 70,
    height: 30,
    width: 30,
    zIndex: 9999,
    color: "white",
    backgroundColor: "#00B6C9",
    "&:hover": {
      backgroundColor: "#00B6C9",
    },
  },
  arrowOpen: {
    left: 185,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  arrowClose: {
    left: 37,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  backDrop: {
    zIndex: 100,
    backgroundColor: "rgba(217, 217, 217, 0.80)",
  },
}));

const MiniDrawer = ({ children, open, isTablet, classes1 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleMobileOpen = (flag) => {
    dispatch({ type: "TOGGLE_NAVBAR", payload: flag });
  };
  return (
    <>
      {isTablet && (
        <>
          <Drawer
            variant='permanent'
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes1.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}>
            {children}
          </Drawer>
          <Backdrop
            className={classes.backDrop}
            open={open}
            onClick={() => handleMobileOpen(false)}
          />
        </>
      )}

      {!isTablet && (
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, classes.drawerAboveTabSize)}
          classes={{
            paper: clsx(classes1.drawer, classes.drawerAboveTabSize),
          }}>
          {children}
        </Drawer>
      )}
    </>
  );
};

export default MiniDrawer;
