import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, Button, Divider } from "@material-ui/core";
import MyContext from "../../../Provider/MyContext";
import { H3, AvenirBlackH4, H5, H5Grey, H6 } from "../../../utils/text";
import colors from "../../../styles/colors";
import {
  CloseCross,
  CirclePluse as CirclePluseIcon,
  InfoOutlined,
} from "../../../components/SVG";
import { validateAlphaNumeric } from "../../../utils/util";
import styles from "../../../styles/index";
import {
  ModifierItem,
  InputText,
  DraggableList,
  CenterModal,
  ModifierSetQuantityLimit,
  ModifierMinMaxSelect,
} from "../../../components";
import { useModifierGroup } from "../../../custom-hooks";

const ModifierGroupCreate = ({
  open,
  initialValues = null,
  onClose,
  handleCreate = () => {},
}) => {
  const {
    modifierGroup,
    errors,
    updateModifierGroup,
    updateAddonItem,
    addAddonItem,
    removeAddonItem,
    validateModifierGroup,
  } = useModifierGroup(initialValues);
  const style = styles();
  const Context = useContext(MyContext);
  const [maxSelectionFlag, setMaxSelectionFlag] = useState(true);
  const [quantityLimFlag, setQuantityLimFlag] = useState(false);

  const handleDragEnd = (startIndex, endIndex) => {
    const reorderedItems = Array.from(modifierGroup?.addon_items);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    const finalReorder = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
    }));
    updateModifierGroup("addon_items", finalReorder);
  };

  const handleMaxCheckboxChange = (e) => {
    setMaxSelectionFlag(e);
    updateModifierGroup("set_max_selection", e);
  };

  const handleLimitCheckboxChange = (e) => {
    setQuantityLimFlag(e);
    updateModifierGroup("set_quantity_limit", e);
  };

  const handleMaxValueChange = (e) => {
    // setmaxValue(e);
    updateModifierGroup("maximum_select", e);
  };

  const handleCreateModifier = () => {
    if (validateModifierGroup()) handleCreate(modifierGroup);
  };

  useEffect(() => {
    if (modifierGroup.maximum_select > modifierGroup.maximum_quantity) {
      updateModifierGroup("maximum_quantity", modifierGroup.maximum_quantity);
    }
  }, [modifierGroup.addon_items]);

  const renderItem = (item, index) => (
    <ModifierItem
      key={index}
      addonItemName={item.addon_item_name}
      basePrice={item.base_price}
      handleDeleteItem={() => removeAddonItem(index)}
      onItemNameChange={(value) =>
        updateAddonItem(index, "addon_item_name", value)
      }
      onPriceChange={(value) => updateAddonItem(index, "base_price", value)}
      errorModifierName={errors[`addon_item_name_${index}`]}
      errorBasePrice={errors[`base_price_${index}`]}
    />
  );
  return (
    <CenterModal open={open} padding={1}>
      <Grid
        style={{
          display: "flex",
          height: "85vh",
          flexDirection: "column",
          width: 1045,
          borderRadius: "5px",
        }}>
        <Grid
          data-qaid='assignCategories__child1_grid'
          style={{
            display: "flex",
            height: "100%",
            width: "98%",
            marginTop: 10,
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            style={{
              display: "flex",
              height: "10%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Grid>
              <H6
                data-qaid='createaddon_valuelabel'
                label={Context.langData.create_modifier_group}
                style={{ margin: "20px 36px 20px 36px" }}
              />
            </Grid>
            <Grid data-qaid='createaddon__close_grid'>
              <IconButton onClick={onClose} style={{ marginRight: 10 }}>
                <CloseCross data-qaid='assignCategories__closeicon' size={20} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              marginLeft: 36,
              marginBottom: 10,
              marginRight: 36,
              width: "95%",
              height: "600px", // Set a fixed height
              overflowY: "auto", // Enable vertical scrolling
            }}>
            <Grid item xs={12} md={6}>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  marginRight: 20,
                  width: "95%",
                }}>
                <H3
                  data-qaid='createaddon_valuelabel'
                  label={Context.langData.modifier_group_name}
                />
                <InputText
                  value={modifierGroup?.addon_cat_name}
                  error={errors.addon_cat_name}
                  onChange={(val) =>
                    updateModifierGroup(
                      "addon_cat_name",
                      validateAlphaNumeric(val.target.value)
                    )
                  }
                  maxLength={40}
                  inputPlaceholder={Context.langData.modifier_group_name}
                  id='addon_cat_name'
                />
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  borderBottom: "1px solid #EEEEEE",
                }}>
                <H3
                  data-qaid='createaddon_valuelabel'
                  label={Context.langData.modifier_items}
                  style={{ marginBottom: 10 }}
                />
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 15,
                    cursor: "pointer",
                    width: "80%",
                    justifyContent: "space-between",
                    padding: "10px 0px",
                  }}>
                  <Grid style={{ width: "50%", marginLeft: 40 }}>
                    <AvenirBlackH4 label={Context.langData.name} />
                  </Grid>
                  <Grid style={{ width: "30%" }}>
                    <AvenirBlackH4 label={Context.langData.price} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    width: "90%",
                    justifyContent: "space-between",
                    padding: "10px 0px",
                  }}>
                  {modifierGroup?.addon_items && (
                    <DraggableList
                      items={modifierGroup?.addon_items}
                      onDragEnd={handleDragEnd}
                      renderItem={renderItem}
                      isEditing
                      gridItemProps={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      position='position'
                    />
                  )}
                  {errors.addon_items && (
                    <Grid
                      style={{
                        color: "#AB1212",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: 0,
                        marginTop: 8,
                      }}>
                      <InfoOutlined
                        width={16}
                        height={16}
                        color='#AB1212'
                        style={{ marginRight: 6 }}
                      />
                      <H5
                        label={errors.addon_items}
                        style={{ color: "#AB1212 " }}
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 15,
                      cursor: "pointer",
                      width: "100%",
                      justifyContent: "center",
                      padding: "20px 0px",
                    }}
                    onClick={addAddonItem}>
                    <CirclePluseIcon
                      data-qaid='createaddon-CirclePluseIcon'
                      color='#007191'
                      style={{ marginRight: 10 }}
                    />
                    <AvenirBlackH4
                      data-qaid='createaddon__add'
                      label={Context.langData.add_modifier_item}
                      style={{ color: "#007191" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  borderBottom: "1px solid #EEEEEE",
                  marginBottom: 40,
                }}>
                <H3
                  data-qaid='modify_group_settingslabel'
                  label={Context.langData.modifier_group_settings}
                  style={{ marginBottom: 15 }}
                />
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                <AvenirBlackH4
                  data-qaid='total_number_of_selectionlabel'
                  label={Context.langData.total_number_of_selections}
                />
                <H5Grey
                  data-qaid='total_number_of_selection_commentlabel'
                  label={Context.langData.total_number_of_selections_comment}
                  style={{ marginTop: 10 }}
                />
              </Grid>
              <ModifierMinMaxSelect
                id='set_max_selection'
                initialCountOfMin={modifierGroup.minimum_select}
                initialCountOFmax={
                  modifierGroup.minimum_select >= modifierGroup.maximum_select
                    ? modifierGroup.minimum_select
                    : modifierGroup.maximum_select
                }
                checked={maxSelectionFlag}
                minOfMin={0}
                maxOfMin={modifierGroup?.addon_items?.length || 1}
                minOfMax={
                  modifierGroup.minimum_select > 1
                    ? modifierGroup.minimum_select
                    : 1
                }
                maxOfMax={50}
                multiSelectMax={50}
                onCheckboxChange={(value) => handleMaxCheckboxChange(value)}
                onMinChange={(value) =>
                  updateModifierGroup("minimum_select", value)
                }
                onMaxChange={(value) => handleMaxValueChange(value)}
              />
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                  marginBottom: 20,
                }}>
                <ModifierSetQuantityLimit
                  checked={quantityLimFlag}
                  checkBoxLabel={Context.langData.set_quantity_limit}
                  initialCountOfMax={modifierGroup.maximum_quantity}
                  minOfMax={1}
                  maxOfMax={modifierGroup.maximum_select}
                  onCheckboxChange={(value) => handleLimitCheckboxChange(value)}
                  onMaxChange={(value) =>
                    updateModifierGroup("maximum_quantity", value)
                  }
                  ModifierSetQuantityLimitDisable={!maxSelectionFlag}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            data-qaid='assignCategories_cancel_main_Grid'
            style={{
              display: "flex",
              height: "5%",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}>
            <Grid
              data-qaid='assignCategories_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px ",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_cancelButton'
                style={{
                  backgroundColor: "#FFF",
                  border: "2px solid #051D33",
                  marginRight: 20,
                  marginLeft: 20,
                  width: "100px",
                }}
                variant='contained'
                id='cancel'
                onClick={onClose}>
                <AvenirBlackH4
                  data-qaid='assignCategories_cancelLabel'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_saveButton'
                style={{
                  backgroundColor:
                    modifierGroup.addon_items?.length === 0
                      ? colors.greyText
                      : "#FFB600",
                  color: "white",
                  marginRight: 30,
                  width: "100px",
                }}
                disabled={modifierGroup.addon_items?.length.length === 0}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={handleCreateModifier}>
                <AvenirBlackH4
                  data-qaid='assignCategories_saveLabel'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CenterModal>
  );
};

export default ModifierGroupCreate;
