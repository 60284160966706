import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import _ from "lodash";
import styles from "../../../../styles/index";
import {
  deleteOffers,
  fetchOffers,
  createOffers,
  EditOffers,
} from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import { H4, H2, AvenirBlackH4, H8 } from "../../../../utils/text";
import {
  SingleSelect,
  SnackBar,
  NoData,
  RightDrawer,
  InputText,
  MainBodyWrapper,
  TitleWrapper,
  NewDataTable,
} from "../../../../components";
import AlertDialog from "../../../../components/Dialog";
import {
  CloseCircle,
  Settings as SettingIcon,
  DownVector,
  CircleClose,
  Delete as DeleteIcon,
  DisableDelete,
  CirclePluse,
} from "../../../../components/SVG";
import { validateAlphaNumeric } from "../../../../utils/util";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mainDiv: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
  },
  // eslint-disable-next-line no-dupe-keys
  root: {
    "&$checked": {
      color: "#004C60",
    },
  },
  checked: {
    color: "#707272",
  },
}));

const OffersList = () => {
  const style = styles();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: Context.langData.name,
      size: "40%",
      sort: false,
    },
    {
      field: "promotion_type",
      headerName: Context.langData.type,
      size: "30%",
      sort: false,
    },
    {
      field: "discount",
      headerName: Context.langData.value,
      size: "30%",
      sort: false,
    },
    {
      field: "status",
      headerName: Context.langData.status,
      size: "30%",
      sort: false,
      statusFlag: true,
    },
  ]);
  const Offers = useSelector((state) => state.settingsReducer.offers);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [assignOffer, toggleAssignOfferDrawer] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [isDiscount] = useState(true);
  const [row, setRow] = useState({});
  const [selectedTabData, setSelectedTabData] = useState([]);
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const heading =
    selectedId.length > 1
      ? Context.langData.delete_discounts
      : Context.langData.delete_discount;
  const bodyMessage =
    selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} discounts?`
      : Context.langData.delete_body;
  useEffect(() => {
    const filterOffersData = _.reverse(Offers);
    if (filterOffersData) {
      filterOffersData.map((data) => {
        let promotionType = data.promotion_type;
        if (promotionType && promotionType.endsWith("Promotion")) {
          promotionType = promotionType.substring(0, promotionType.length - 9);
        }
        data.promotion_type = promotionType;
        return data;
      });
    }
    setSelectedTabData(filterOffersData);
  }, [Offers]);
  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  const commanCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchOffers());
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.discount_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.discount_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.discount_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.discount_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message:
          selectedId.length > 1
            ? Context.langData.discounts_delete_succ
            : Context.langData.discount_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.discount_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteOffers({ promotion_id_list: selectedId }, deleteCallback));
    }
    setShowDialog(false);
  };
  const deletePrinter = (value) => {
    setShowDialog(value);
  };

  const onDeleteOffer = () => {
    if (selectedId.length > 0) {
      deletePrinter(true);
    }
  };

  const sortingOnClick = ({ field, order }) => {
    const value = field;
    const sortValue = order === "asc";
    let index = columns.findIndex((col) => col.field === field);
    if (index === -1) {
      index = 0;
    }

    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    if (value === "discount") {
      const sortarray = _.orderBy(
        selectedTabData,
        [(d) => parseInt(d[value].replace(/[^0-9.-]+/g, ""), 10)],
        [sortValue ? "asc" : "desc"]
      );
      setSelectedTabData(sortarray);
    } else {
      const sortarray = _.orderBy(
        selectedTabData,
        [value],
        [sortValue ? "asc" : "desc"]
      );
      setSelectedTabData(sortarray);
    }
  };
  return (
    <MainBodyWrapper>
      <div data-qaid='offersList_div'>
        {Offers.length > 0 ? (
          <Grid data-qaid='offersList_buttongrid' item xs={12}>
            <TitleWrapper
              mainTitle={Context.langData.settings}
              subTitle={Context.langData.discounts}>
              <Grid
                data-qaid='offersList_add grid'
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='otype_delete_button'
                  style={{ color: "white", marginRight: 10 }}
                  variant='text'
                  id='cancel'
                  onClick={onDeleteOffer}
                  disabled={selectedId?.length <= 0}>
                  {selectedId?.length <= 0 ? (
                    <DisableDelete
                      data-qaid='shifts_deleteicon'
                      width={16}
                      height={16}
                      style={{
                        marginRight: 5,
                      }}
                    />
                  ) : (
                    <DeleteIcon
                      data-qaid='shifts_deleteicon'
                      width={16}
                      height={16}
                      style={{
                        marginRight: 5,
                      }}
                    />
                  )}

                  <AvenirBlackH4
                    data-qaid='otype_delete_label'
                    label={Context.langData.delete}
                    style={{
                      color: selectedId?.length <= 0 ? "#C3C3C3" : "#004C60",
                    }}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='offersList_addbutton'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleCreateDrawer(true)}>
                  <CirclePluse data-qaid='offersList_circleicon' />

                  <AvenirBlackH4
                    data-qaid='offersList_createlabel'
                    label={Context.langData.create_discount}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
              </Grid>
            </TitleWrapper>

            {Offers.length > 0 ? (
              <NewDataTable
                data-qaid='offersList_datatable'
                columns={columns}
                rows={selectedTabData}
                refresh={openCreate}
                onCheckedIds={onCheckedIds}
                onClickAction={onClickAction}
                sortingOnClick={sortingOnClick}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <AvenirBlackH4
                  data-qaid='offersList_nodata'
                  label={Context.langData.no_data}
                />
              </div>
            )}
          </Grid>
        ) : (
          <NoData page='discount' callback={() => toggleCreateDrawer(true)} />
        )}
        {openCreate && (
          <CreateOffers
            data-qaid='offersList_createoffer'
            open={openCreate}
            onClose={() => toggleCreateDrawer(false)}
            offers={Offers}
            isDiscount={isDiscount}
            // eslint-disable-next-line react/jsx-no-bind
            callback={createCallback}
          />
        )}
        {openEdit && (
          <EditOffersDrawer
            data-qaid='offersList_editoffer'
            open={openEdit}
            row={row}
            offers={Offers}
            isDiscount={isDiscount}
            onClose={() => toggleEditDrawer(false)}
            // eslint-disable-next-line react/jsx-no-bind
            callback={editCallback}
          />
        )}
        {assignOffer && (
          <AssignOffersDrawer
            data-qaid='offersList_asignOffer'
            open={assignOffer}
            offers={Offers}
            onClose={() => toggleAssignOfferDrawer(false)}
            // eslint-disable-next-line react/jsx-no-bind
            callback={commanCallback}
          />
        )}
        {snackbar && (
          <SnackBar
            data-qaid='taxeslist_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
        <AlertDialog
          data-qaid='offersList_alertdialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={heading}
          IsError={false}
          handleClose={handleClose}
        />
      </div>
    </MainBodyWrapper>
  );
};
const CreateOffers = ({ open, callback, onClose, offers }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [xValue, setXValue] = useState("");
  const [nameError, setNameError] = useState(null);
  const [valueError, setValueError] = useState(null);
  const dispatch = useDispatch();
  const [value, setValue] = useState("ItemPromotion");
  const [discountUnit, setDiscountUnit] = useState("percentage");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChangeColorToggleBtn = (event, newDiscountUnit) => {
    setDiscountUnit(newDiscountUnit);
  };
  const save = () => {
    let errorFlag = 0;
    if (xValue) {
      if (discountUnit === "flat") {
        setValueError(null);
      } else if (discountUnit === "percentage" && xValue > 100) {
        errorFlag += 1;
        setValueError(Context.langData.enter_valid_number);
      } else {
        setValueError(null);
      }
    } else {
      errorFlag += 1;
      setValueError(Context.langData.enter_valid_number);
    }
    if (name) {
      const elementsIndex = offers.findIndex(
        (indexElement) => indexElement.name.toUpperCase() === name.toUpperCase()
      );
      if (elementsIndex === -1) {
        setNameError(null);
      } else {
        errorFlag += 1;
        setNameError(`${name} already exist`);
      }
    } else {
      errorFlag += 1;
      setNameError(Context.langData.enter_valid_name);
    }

    if (errorFlag === 0) {
      const discountValue = xValue;
      dispatch(
        createOffers(
          {
            name: name.trim(),
            promotion_type: value,
            discount: parseFloat(discountValue, 10),
            discount_type: discountUnit,
            description: "",
          },
          callback
        )
      );
    } else {
      /* empty */
    }
  };
  const onChange = (val) => {
    setNameError(null);
    setName(validateAlphaNumeric(val));
  };
  const onValueChange = (val) => {
    if (val === "") {
      setXValue(val);
    } else if (/^\d*\.?\d{0,2}$/.test(val)) {
      const num = parseFloat(val);
      if (!(discountUnit === "percentage" && num > 100)) {
        setXValue(val);
      }
    }
  };

  return (
    <RightDrawer
      data-qaid='createcomment_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <Grid
        data-qaid='createOffer_main_grid'
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          data-qaid='createOffer_child_grid'
          style={{ display: "flex", height: "85%", flexDirection: "column" }}>
          <Grid
            data-qaid='createOffer_close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='createOffer_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='createOffer_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}
          />
          <H8
            data-qaid='createOffer_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.create_discount}
          />
          <Grid
            data-qaid='createOffer_grid1'
            style={{
              marginLeft: 56,
              marginRight: 56,
              width: 500,
              flexDirection: "column",
              marginBottom: 20,
            }}>
            <FormControl>
              <FormLabel id='demo-controlled-radio-buttons-group'>
                <AvenirBlackH4 data-qaid='createOffer_namelabel' label='Type' />
              </FormLabel>
              <RadioGroup
                style={{ marginBottom: 20 }}
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                row
                value={value}
                onChange={handleChange}>
                <FormControlLabel
                  value='ItemPromotion'
                  control={<Radio />}
                  label='Item'
                />
                <FormControlLabel
                  value='OrderPromotion'
                  control={<Radio />}
                  label='Order'
                />
              </RadioGroup>
            </FormControl>

            <Grid
              data-qaid='createOffer_namegrid'
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: 20,
              }}>
              <AvenirBlackH4
                data-qaid='createOffer_namelabel'
                label={Context.langData.name}
                style={{ marginBottom: 15 }}
              />
              <InputText
                data-qaid='createOffer_nameip'
                value={name}
                maxLength={128}
                error={nameError}
                onChange={(val) => onChange(val.target.value)}
                id='name'
              />
            </Grid>
            <Grid
              data-qaid='createOffer_x'
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}>
              <Grid
                data-qaid='createOffer_xgrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  paddingTop: 20,
                }}>
                <AvenirBlackH4 data-qaid='createOffer_xlabel' label='Value' />
                <Grid
                  data-qaid='createOffer_y'
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}>
                  <ToggleButtonGroup
                    style={{
                      marginTop: 10,
                      height: 56,
                    }}
                    color='#004C60'
                    value={discountUnit}
                    exclusive
                    onChange={handleChangeColorToggleBtn}
                    aria-label='Platform'>
                    <ToggleButton
                      style={{
                        width: 50,
                        borderColor: "#004C60",
                        color:
                          discountUnit === "percentage" ? "white" : "#051D33",
                        backgroundColor:
                          discountUnit === "percentage" ? "#004C60" : "white",
                      }}
                      value='percentage'>
                      %
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        width: 50,
                        borderColor: "#004C60",
                        color: discountUnit === "flat" ? "white" : "#051D33",
                        backgroundColor:
                          discountUnit === "flat" ? "#004C60" : "white",
                      }}
                      value='flat'>
                      $
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <InputText
                    data-qaid='createOffer_xip'
                    value={xValue}
                    error={valueError}
                    type='number'
                    min='0'
                    isPlaceHolder={false}
                    maxLength={8}
                    // placeholder={"$10 or 10%"}
                    onChange={(val) => onValueChange(val.target.value)}
                    variant='outlined'
                    id='x_value'
                    style={{
                      marginLeft: 10,
                      paddingTop: 10,
                      marginTop: 1,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid
            data-qaid='createoffer_action_grid'
            container
            style={{
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
            }}>
            <Grid
              data-qaid='createoffer_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createoffer_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createoffer_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='createoffer_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

const EditOffersDrawer = ({ open, row, callback, onClose, offers }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [xValue, setXValue] = useState("");
  const [id, setId] = useState("");
  const [nameError, setNameError] = useState(null);
  const [valueError, setValueError] = useState(null);
  const [enable, setEnable] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [discountUnit, setDiscountUnit] = useState("");

  const handleChangeColorToggleBtn1 = (event, newDiscountUnit) => {
    setDiscountUnit(newDiscountUnit);
  };
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (row) {
      let offerVal = "";
      const promotionType = `${row.promotion_type}Promotion`;
      if (row.discount_type === "flat") {
        offerVal = row.discount.replace("$", "");
      } else if (row.discount_type === "percentage") {
        offerVal = row.discount.replace("%", "");
      } else {
        offerVal = "";
      }
      setId(row.id);
      setName(row.name);
      setValue(promotionType);
      setDiscountUnit(row.discount_type);
      setXValue(offerVal);
      if (row.active === 1) {
        setEnable(true);
      } else {
        setEnable(false);
      }
    }
  }, []);
  const save = () => {
    let errorFlag = 0;

    if (xValue) {
      if (discountUnit === "flat") {
        setValueError(null);
      } else if (discountUnit === "percentage" && xValue > 100) {
        errorFlag += 1;
        setValueError(Context.langData.enter_valid_number);
      } else {
        setValueError(null);
      }
    } else {
      errorFlag += 1;
      setValueError(Context.langData.enter_valid_number);
    }
    if (name) {
      /* empty */
    } else {
      errorFlag += 1;
      setNameError(Context.langData.enter_valid_name);
    }
    const isArray = offers.filter((e) => e.id !== id);
    const isIndex = isArray.findIndex(
      (e) => e.name.toUpperCase() === name.toUpperCase()
    );
    if (isIndex !== -1) {
      errorFlag += 1;
      setNameError(`${name} already exist`);
    }
    if (errorFlag === 0) {
      dispatch(
        EditOffers(
          {
            name: name.trim(),
            promotion_type: value,
            active: enable ? 1 : 0,
            promotion_id: id,
            discount: parseFloat(xValue, 10),
            discount_type: discountUnit,
            description: "",
          },
          callback
        )
      );
    } else {
      /* empty */
    }
  };
  const onChange = (val) => {
    setNameError(null);
    setName(validateAlphaNumeric(val));
  };
  const onValueChange = (val) => {
    if (val === "") {
      setXValue(val);
    } else if (/^\d*\.?\d{0,2}$/.test(val)) {
      const num = parseFloat(val);
      if (!(discountUnit === "percentage" && num > 100)) {
        setXValue(val);
      }
    }
  };

  const handleCheckbox = () => {
    setEnable(!enable);
  };

  return (
    <RightDrawer
      data-qaid='createcomment_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <Grid
        data-qaid='createOffer_main_grid'
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          data-qaid='createOffer_child_grid'
          style={{ display: "flex", height: "85%", flexDirection: "column" }}>
          <Grid
            data-qaid='createOffer_close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='createOffer_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='createOffer_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            {/* <SettingIcon
                data-qaid='createOffer_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              /> */}
            {/* <H4
                data-qaid='createOffer_settings'
                label={Context.langData.settings}
                style={{ color: "#707272" }}
              /> */}
          </Grid>
          <H8
            data-qaid='createOffer_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.edit_discount}
          />

          <Grid
            data-qaid='editOffer_grid1'
            style={{
              marginLeft: 56,
              marginRight: 56,
              width: 500,
              flexDirection: "column",
            }}
            container
            direction='column'>
            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Grid
                data-qaid='editOffer_namegrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 15,
                  width: "100%",
                }}>
                <FormControl>
                  <FormLabel id='demo-controlled-radio-buttons-group'>
                    <AvenirBlackH4
                      data-qaid='createOffer_namelabel'
                      label='Type'
                    />
                  </FormLabel>
                  <RadioGroup
                    style={{ marginBottom: 20 }}
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    row
                    value={value}
                    onChange={handleChange}>
                    <FormControlLabel
                      label='Item'
                      value='ItemPromotion'
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value='OrderPromotion'
                      control={<Radio />}
                      label='Order'
                    />
                  </RadioGroup>
                </FormControl>
                <AvenirBlackH4
                  data-qaid='editOffer_namelabel'
                  label={Context.langData.name}
                  style={{ marginBottom: 15 }}
                />
                <InputText
                  data-qaid='editOffer_nameip'
                  value={name}
                  error={nameError}
                  maxLength={128}
                  onChange={(val) => onChange(val.target.value)}
                  id='name'
                />
              </Grid>
            </Grid>
            <Grid
              data-qaid='createOffer_x'
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}>
              <Grid
                data-qaid='createOffer_xgrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  paddingTop: 15,
                }}>
                <AvenirBlackH4 data-qaid='createOffer_xlabel' label='Value' />
                <Grid
                  data-qaid='createOffer_y'
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}>
                  <ToggleButtonGroup
                    style={{
                      marginTop: 10,
                      height: 56,
                    }}
                    color='#004C60'
                    value={discountUnit}
                    exclusive
                    onChange={handleChangeColorToggleBtn1}
                    aria-label='Platform'>
                    <ToggleButton
                      style={{
                        width: 50,
                        borderColor: "#004C60",
                        color:
                          discountUnit === "percentage" ? "white" : "#051D33",
                        backgroundColor:
                          discountUnit === "percentage" ? "#004C60" : "white",
                      }}
                      value='percentage'>
                      %
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        width: 50,
                        borderColor: "#004C60",
                        color: discountUnit === "flat" ? "white" : "#051D33",
                        backgroundColor:
                          discountUnit === "flat" ? "#004C60" : "white",
                      }}
                      value='flat'>
                      $
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <InputText
                    data-qaid='createOffer_xip'
                    value={xValue}
                    error={valueError}
                    isPlaceHolder={false}
                    maxLength={8}
                    type='number'
                    min='0'
                    // placeholder={"$10 or 10%"}
                    onChange={(val) => onValueChange(val.target.value)}
                    variant='outlined'
                    id='x_value'
                    style={{
                      marginLeft: 15,
                      paddingTop: 10,
                      marginTop: 1,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              data-qaid='editOffer_checkgrid'
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                paddingTop: 25,
              }}>
              <AvenirBlackH4 data-qaid='createOffer_xlabel' label='Status' />
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 10,
                }}>
                <Checkbox
                  style={{ padding: 0, paddingRight: 10 }}
                  data-qaid='editOffer_check'
                  checked={enable}
                  onChange={handleCheckbox}
                  id='enable'
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
                <H4 data-qaid='editOffer_checklabel' label='Active' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid
            data-qaid='createoffer_action_grid'
            container
            style={{
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
            }}>
            <Grid
              data-qaid='createoffer_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createoffer_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createoffer_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='createoffer_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

const AssignOffersDrawer = ({ open, onClose, offers }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const [checkedList, setCheckedList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.menu.categories);
  function checkIt(id) {
    const permission = [...checkedList];
    const index = permission.indexOf(id);
    if (index === -1) {
      permission.push(id);
    } else {
      permission.splice(index, 1);
    }
    setCheckedList(permission);
  }
  const save = () => {
    onClose();
  };
  return (
    <RightDrawer
      data-qaid='assignOffers_centermodal'
      open={open}
      onClose={onClose}>
      <>
        <Grid
          data-qaid='assignOffers_close_grid'
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}>
          <CloseCircle
            data-qaid='assignOffers_closeicon'
            size={20}
            onClick={onClose}
            style={{ marginRight: 10 }}
          />
        </Grid>
        <Grid
          data-qaid='assignOffers_settings_grid'
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginLeft: 56,
            marginBottom: 10,
          }}>
          <SettingIcon
            data-qaid='assignOffers_setting_icon'
            style={{ marginRight: 10 }}
            width={16}
            height={16}
            color='#707272'
          />
          <H4
            data-qaid='assignOffers_settings'
            label={Context.langData.settings}
            style={{ color: "#707272" }}
          />
        </Grid>
        <H2
          data-qaid='assignOffers_title'
          style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
          label={Context.langData.assign_discounts}
        />
        {categories && categories.length > 0 ? (
          categories.map((item, index) => (
            <Accordion
              data-qaid='assignOffers_accordion'
              key={index}
              expanded={checkedList.indexOf(item.cat_id) !== -1}
              onChange={() => checkIt(item.cat_id)}
              style={{
                marginLeft: 56,
                marginRight: 56,
                paddingTop: 10,
                boxShadow: "none",
              }}>
              <AccordionSummary
                data-qaid='assignOffers_accrdionsummary'
                expandIcon={<DownVector />}
                aria-controls='panel2a-content'
                id='panel2a-header'>
                <H4 data-qaid='assignOffers_cat_name' label={item.name} />
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  data-qaid='assignOffers_dineinlabel_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 24,
                  }}>
                  <AvenirBlackH4
                    data-qaid='assignOffers_dineinlabel'
                    label={Context.langData.dine_in}
                  />
                  <SingleSelect
                    data-qaid='assignOffers_ordertype_singleselect'
                    list={offers}
                    value='name'
                    valueKey='name'
                    displayKey='name'
                    placeholder={Context.langData.n_a}
                    border
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Grid />
        )}
        <Grid
          data-qaid='assignOffers_action_grid'
          container
          style={{
            backgroundColor: "#FAFAFA",
            border: "1px solid #C3C3C3",
            marginTop: "100%",
          }}>
          <Grid
            data-qaid='assignOffers_cancel_btn'
            item
            xs={12}
            lg={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 32,
              marginBottom: 32,
            }}>
            <Button
              className={style.buttonClass}
              data-qaid='assignOffers_save_btn'
              variant='contained'
              style={{
                backgroundColor: "#FFB600",
                marginRight: 32,
                height: 40,
                width: 90,
              }}
              onClick={save}>
              <AvenirBlackH4
                data-qaid='assignOffers_save_text'
                label={Context.langData.save}
              />
            </Button>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default OffersList;
