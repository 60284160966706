import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import theme from "../theme";
import { AvenirBlackH3, AvenirBlackH4, DotText } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { ConvertPrice } from "../utils/util";
import { ArrowsIcon, RightArrow, DownArrow } from "./SVG";
import { CustomPagination } from ".";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: "bold",
    padding: theme.spacing(1),
    backgroundColor: "#f4f4f5",
  },
  cell: {
    padding: theme.spacing(1),
  },
  textStyles: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 5,
    "&:hover": {
      overflow: "visible",
      whiteSpace: "normal",
    },
  },
  divHover: {
    flexDirextion: "row",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    minHeight: 56,
    marginTop: 10,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#FAFAFA",
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "#C8E6F1",
    },
  },
});
const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: "#051D33",
  },
  tooltip: {
    backgroundColor: "#051D33",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "AvenirLT-Medium",
    color: "#FFFFFF",
  },
}));
const SimpleTable = ({
  columns = [],
  rows = [],
  onPageChange = null,
  onRowClick = null,
  sortingOnClick = null,
  isEditArrow = true,
  total = 1,
  // showPointer = true,
  // cursor,
  isOnClick = true,
  displayFontSize,
}) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  function onSortClick(value, sort, index) {
    if (sortingOnClick) {
      sortingOnClick(value, sort, index);
    }
  }
  const totalNumber = (total) => {
    const totalPages = Math.floor(total / 25) + (total % 25 !== 0 ? 1 : 0);
    return totalPages;
  };
  const getCoulmnDisplayName = (isNumber, value) => {
    // eslint-disable-next-line no-var
    var value = isNumber
      ? `$${typeof value === "string" ? value : ConvertPrice(String(value))}`
      : String(value);
    return value;
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const BootstrapTooltip = (props) => {
    const classes1 = useStylesBootstrap();
    return <Tooltip arrow classes={classes1} {...props} placement='top' />;
  };
  return (
    <Grid
      data-qaid='simpletable_main_grid'
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid='simpletable_header_grid'
        style={{
          flexDirextion: "row",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: 50,
          width: "100%",
          backgroundColor: "#FFF",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
        }}>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            width: isEditArrow ? "99%" : "100%",
            alignItems: "center",
          }}>
          {columns.map((column, index3) => (
            <Grid
              data-qaid='simpletable_header_item_grid'
              key={index3}
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
                gap: "0 10px",
                cursor: "pointer",
                width: column.size,
                paddingRight: 10,
                alignItems: "center",
              }}
              onClick={() => {
                onSortClick(
                  column.sortKey || column.field,
                  !column.sort,
                  index3
                );
              }}>
              {column.tooltip ? (
                <BootstrapTooltip title={column.tooltip}>
                  <Grid
                    data-qaid='simpletable_column_button'
                    style={{
                      // backgroundColor: '#FFFFFF',
                      display: "flex",
                      justifyContent: "flex-start",
                    }}>
                    <AvenirBlackH3
                      style={{ fontSize: displayFontSize }}
                      data-qaid='simpletable_column_label'
                      label={column.display}
                    />
                  </Grid>
                </BootstrapTooltip>
              ) : (
                <AvenirBlackH3
                  style={{ fontSize: displayFontSize }}
                  data-qaid='simpletable_column_label'
                  className={classes.textStyles}
                  label={column.display}
                />
              )}
              {!(Context.langData.modifier === column.display) &&
                !column.removeIcon && (
                  <DownArrow data-qaid='simpletable_arrowicon' />
                )}
            </Grid>
          ))}
        </Grid>
        {isEditArrow && (
          <Grid
            data-qaid='simpletable_header_item_grid2'
            style={{ width: "3%" }}
          />
        )}
      </Grid>
      {rows.length > 0 ? (
        rows.map((row, index) => (
          <Grid
            data-qaid='simpletable_header_grid'
            key={index}
            className={classes.divHover}
            onClick={
              isOnClick
                ? (e) => {
                    onRowClick(row, e);
                  }
                : () => {}
            }>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                width: isEditArrow ? "97%" : "100%",
              }}>
              {columns.map((colItem, index3) => (
                <Grid
                  data-qaid='simpletable_column_item_grid'
                  key={index3}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: 10,
                    cursor: "pointer",
                    width: colItem.size,
                  }}>
                  {typeof row[colItem.field] === "string" ||
                  typeof row[colItem.field] === "number" ? (
                    <DotText
                      className='two_rows_text'
                      data-qaid='simpletable_row_label'
                      label={getCoulmnDisplayName(
                        colItem.isNumber || false,
                        row[colItem.field]
                      )}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  ) : (
                    row[colItem.field]
                  )}
                  <ArrowsIcon
                    data-qaid='simpletable_arrowsicon'
                    style={{ display: "none", paddingRight: 8 }}
                  />
                </Grid>
              ))}
            </Grid>
            {isEditArrow && (
              <Grid
                data-qaid='simpletable_header_item_grid2'
                style={{
                  width: "3%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}>
                <RightArrow
                  data-qaid='simpletable_rightarrow_icon'
                  style={{ marginRight: 5 }}
                />
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <AvenirBlackH4
          data-qaid='simpletable_row_label'
          label={Context.langData.no_results_available}
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
      <Grid
        data-qaid='simpletable_footer_grid'
        container
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          width: "100%",
        }}>
        {onPageChange && (
          <Grid data-qaid='simpletable_custompagination_grid'>
            <CustomPagination
              data-qaid='simpletable_custompagination'
              total={total !== 1 ? totalNumber(total) : 1}
              onChange={onPageChange}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SimpleTable;
