/* eslint-disable no-unused-expressions */
import React, { useEffect, useContext } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { AvenirBlackH3, DotText } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { ConvertPrice } from "../utils/util";
import { ArrowsIcon, RightArrow, DownArrow } from "./SVG";
import { CustomPagination, CustomCheckbox } from ".";

const useStyles = makeStyles({
  textStyles: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 5,
    "&:hover": {
      overflow: "visible",
      whiteSpace: "normal",
    },
  },
  divHover: {
    flexDirextion: "row",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 56,
    marginTop: 10,
    width: "100%",
    backgroundColor: "#FAFAFA",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#C8E6F1",
    },
  },
});

const BasicTable = (props) => {
  const Context = useContext(MyContext);
  const classes = useStyles();
  const [selectedId, setSelectedId] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  useEffect(() => {
    if (props.onCheckedIds) {
      props.onCheckedIds(selectedId);
    }
  }, [selectedId]);
  useEffect(() => {
    if (props.refresh) {
      setSelectedId([]);
      setCheckedAll(false);
    }
  }, [props.refresh]);
  useEffect(() => {
    setSelectedId([]);
    setCheckedAll(false);
  }, [props.rows]);

  function checkIt(id) {
    const ids = [...selectedId];
    const index = ids.indexOf(id);
    if (index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    setSelectedId(ids);
    if (props.rows.length === ids.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }
  function checkAllFunction(value) {
    setCheckedAll(value);
    if (value) {
      const ids = [];
      props.rows.map((item) => {
        if (!item.isDisabled) {
          ids.push(item.id);
        }
      });
      setSelectedId(ids);
    } else {
      setSelectedId([]);
    }
  }
  function onClickAction(row, e) {
    props.onClickAction(row, e);
    setSelectedId([]);
  }
  function onSortClick(value, sort, index) {
    if (props.sortingOnClick) {
      props.sortingOnClick(value, sort, index);
    }
  }
  const getCoulmnDisplayName = (isNumber, value) =>
    isNumber ? `$${ConvertPrice(String(value))}` : String(value);
  return (
    <Grid data-qaid='datatable_main_grid'>
      <Grid style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
        <Grid
          data-qaid='datatable_header_grid'
          style={{
            flexDirextion: "row",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 50,
            width: "100%",
            backgroundColor: "#FFF",
            height: 56,
          }}>
          <Grid
            data-qaid='datatable_column_checkbox_grid'
            style={{ width: "4%", marginRight: "1%" }}>
            <CustomCheckbox
              data-qaid='datatable_column_checkbox'
              checked={checkedAll}
              marginLeft={0}
              onChange={() => checkAllFunction(!checkedAll)}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              alignItems: "center",
            }}>
            {props.columns.map((column, index3) => (
              <Grid
                data-qaid='datatable_header_item_grid'
                key={index3}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: column.size,
                  paddingRight: props.columns.length > 4 ? 18 : 32,
                  alignItems: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  column.showSort !== false &&
                    onSortClick(column.field, column.sort, index3);
                }}>
                <AvenirBlackH3
                  data-qaid='datatable_header_label'
                  label={column.headerName}
                />
                {column.showSort !== false && (
                  <DownArrow data-qaid='datatable_arrowsicon' />
                )}
              </Grid>
            ))}
          </Grid>
          <Grid
            data-qaid='datatable_header_item_grid2'
            style={{ width: "6%" }}
          />
        </Grid>
      </Grid>
      <Grid style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
        {props.rows.length > 0 ? (
          props.rows.map((row, index) => (
            <Grid
              key={index}
              data-qaid='datatable_header_grid'
              className={classes.divHover}>
              <Grid
                data-qaid='datatable_column_checkbox_grid'
                style={{ width: "4%", marginRight: "1%" }}>
                <CustomCheckbox
                  data-qaid='datatable_row_checkbox'
                  checked={selectedId.indexOf(row.id) !== -1}
                  onChange={() => checkIt(row.id)}
                  id={String(row.id)}
                  marginLeft={0}
                  isDisabled={row.isDisabled}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              </Grid>
              <Grid
                style={{ display: "flex", flexDirection: "row", width: "90%" }}>
                {props.columns.map((colItem, index3) => (
                  <Grid
                    data-qaid='datatable_column_item_grid'
                    key={index3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: colItem.size,
                    }}>
                    {typeof row[colItem.field] === "string" ||
                    typeof row[colItem.field] === "number" ? (
                      <div
                        style={{
                          width: "95%",
                          paddingRight: 5,
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <DotText
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          data-qaid='datatable_row_label'
                          className='two_rows_text'
                          label={getCoulmnDisplayName(
                            colItem.isNumber || false,
                            row[colItem.field]
                          )}
                        />
                      </div>
                    ) : (
                      <Grid>{row[colItem.field]}</Grid>
                    )}
                    <ArrowsIcon
                      data-qaid='datatable_arrowsicon'
                      style={{ display: "none", paddingRight: 8 }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid
                data-qaid='datatable_header_item_grid2'
                data-id={row.id}
                data-name={row.name}
                id={row.id}
                onClick={(e) => {
                  onClickAction(row, e);
                }}
                style={{
                  width: "6%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}>
                <RightArrow
                  data-qaid='datatable_right_arrow'
                  data-id={row.id}
                  data-name={row.name}
                  id={row.id}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <AvenirBlackH3
            data-qaid='datatable_row_label'
            label={Context.langData.no_data}
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      </Grid>
      <Grid
        data-qaid='datatable_pagination_main_grid'
        container
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          width: "100%",
        }}>
        {props.onPageChange && (
          <Grid data-qaid='datatable_pagination_grid'>
            <CustomPagination
              data-qaid='datatable_pagination'
              total={props.total || 1}
              onChange={props.onPageChange}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BasicTable;
