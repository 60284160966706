import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import styles from "../../../styles/index";
import { fetchOrderTypes, deleteOrderTypes } from "../../../redux/actions";
import {
  PluseIcon as CirclePluse,
  Delete as DeleteIcon,
  DisableDelete,
} from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4, H5, H6 } from "../../../utils/text";
import {
  DataTable,
  Dialog as AlertDialog,
  SnackBar,
  NoData,
} from "../../../components";
import { useDrawers, useComponentStates } from "../../../custom-hooks";
import OrderTypeCreateEdit from "./OrderTypeCreateEdit";

const OrderTypesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "otype_name",
      headerName: Context.langData.order_type_name,
      size: "40%",
    },
    {
      field: "description",
      headerName: Context.langData.description,
      size: "60%",
    },
  ]);
  const OrderTypes = useSelector((state) => state.metaReducer.ordertypes);
  const Taxes = useSelector((state) => state.settingsReducer.taxes);
  const { drawers, toggleDrawer } = useDrawers({
    openCreate: false,
    openEdit: false,
  });
  const { componentStates, updateComponentStates } = useComponentStates({
    selectedId: [],
    row: {},
    orderTypesData: [],
    snackbar: null,
  });
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const heading = Context.langData.delete_ordertype_head;
  const bodyMessage =
    componentStates.selectedId.length > 1
      ? `Are you sure you want to delete ${componentStates.selectedId.length} order types?`
      : Context.langData.delete_otype_body;

  useEffect(() => {
    if (OrderTypes) {
      updateComponentStates("orderTypesData", OrderTypes);
    }
  }, [OrderTypes]);
  const onCheckedIds = (ids) => {
    updateComponentStates("selectedId", ids);
  };
  const onClickAction = (rowValue) => {
    const modifiedRowValue = {
      otype_id: rowValue.id,
      name: rowValue.otype_name,
      description: rowValue.description,
      order_channel_id: rowValue.order_channel_id,
      delivery_mode_id: rowValue.delivery_mode_id,
    };
    updateComponentStates("row", modifiedRowValue);

    toggleDrawer(
      "openEdit",
      !(rowValue.order_channel_id === 3 || rowValue.order_channel_id === 4)
    );
  };

  const commonCallback = () => {
    toggleDrawer("all", false);
    dispatch(fetchOrderTypes());
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      updateComponentStates("snackbar", {
        message: Context.langData.ordertype_create_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackbar", {
        message: Context.langData.order_type_create_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      updateComponentStates("snackbar", {
        message: Context.langData.ordertype_edit_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackbar", {
        message: Context.langData.order_type_edit_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const deleteCallback = (isSuccess, res) => {
    if (isSuccess) {
      updateComponentStates("snackbar", {
        message: Context.langData.ordertype_delete_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackbar", {
        message: res.message,
        severity: "error",
      });
    }
    commonCallback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(
        deleteOrderTypes(
          { otype_id_list: componentStates.selectedId },
          deleteCallback
        )
      );
    }
    setShowDialog(false);
  };
  function handleDeleteAlert(value) {
    if (componentStates.selectedId.length > 0) {
      setShowDialog(value);
    }
  }

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortShifts = _.orderBy(
      componentStates.orderTypesData,
      [(d) => d[value]?.toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    updateComponentStates("orderTypesData", sortShifts);
  };

  return (
    <div data-qaid='otype_div'>
      {componentStates.orderTypesData.length > 0 ? (
        <Grid
          data-qaid='kitchenList_grid'
          container
          direction='row'
          style={{ padding: 30 }}>
          <Grid data-qaid='kitchenList_buttongrid' item xs={12} sm={12} lg={12}>
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Grid
                  style={{
                    height: "20%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "30%",
                    paddingLeft: "25px",
                  }}>
                  <H5
                    label={Context.langData.menu}
                    style={{
                      color: "#007191",
                      fontWeight: "500",
                      fontSize: "10px",
                    }}
                  />
                  <H6
                    label={Context.langData.order_types}
                    style={{
                      color: "#051D33",
                      fontWeight: "800",
                    }}
                  />
                </Grid>
                <Grid
                  data-qaid='kitchenList_add grid'
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: 20,
                    marginBottom: 10,
                  }}>
                  <Button
                    className={style.buttonClass}
                    data-qaid='otype_delete_button'
                    style={{ color: "white", marginRight: 10 }}
                    variant='text'
                    id='cancel'
                    onClick={() => handleDeleteAlert(true)}
                    disabled={componentStates.selectedId?.length <= 0}>
                    {componentStates.selectedId?.length <= 0 ? (
                      <DisableDelete
                        data-qaid='otype_deleteicon'
                        width={16}
                        height={16}
                        style={{
                          marginRight: 5,
                        }}
                      />
                    ) : (
                      <DeleteIcon
                        data-qaid='otype_deleteicon'
                        width={16}
                        height={16}
                        style={{
                          marginLeft: 5,
                          color: "#8b0000",
                        }}
                      />
                    )}

                    <AvenirBlackH4
                      data-qaid='otype_delete_label'
                      label={Context.langData.delete}
                      style={{
                        color:
                          componentStates.selectedId?.length <= 0
                            ? "#C3C3C3"
                            : "#004C60",
                      }}
                    />
                  </Button>
                  <Button
                    className={style.buttonClass}
                    data-qaid='otype_create_button'
                    style={{ backgroundColor: "#FFB600", color: "white" }}
                    variant='contained'
                    id='cancel'
                    onClick={() => toggleDrawer("openCreate", true)}>
                    <CirclePluse data-qaid='otype_createIcon' />
                    <AvenirBlackH4
                      data-qaid='otype_create_otype'
                      label={Context.langData.create_otypes}
                      style={{ paddingLeft: 5 }}
                    />
                  </Button>
                </Grid>
              </Grid>
              {OrderTypes.length > 0 ? (
                <DataTable
                  columns={columns}
                  rows={componentStates.orderTypesData}
                  onCheckedIds={onCheckedIds}
                  onClickAction={onClickAction}
                  sortingOnClick={sortingOnClick}
                  onPageChange={() => {}}
                />
              ) : (
                <AvenirBlackH4
                  data-qaid='kitchenList_nodata'
                  label={Context.langData.no_data}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <NoData
          page='order type'
          callback={() => toggleDrawer("openCreate", true)}
        />
      )}
      {drawers.openCreate && (
        <OrderTypeCreateEdit
          open={drawers.openCreate}
          onClose={() => toggleDrawer("openCreate", false)}
          orderTypes={OrderTypes}
          taxes={Taxes}
          createCallback={createCallback}
        />
      )}
      {drawers.openEdit && (
        <OrderTypeCreateEdit
          open={drawers.openEdit}
          initialValues={componentStates.row}
          orderTypes={OrderTypes}
          taxes={Taxes}
          onClose={() => toggleDrawer("openEdit", false)}
          editCallback={editCallback}
          deleteCallback={deleteCallback}
        />
      )}

      {componentStates.snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => updateComponentStates("snackbar", null)}
          severity={componentStates.snackbar.severity}
          message={componentStates.snackbar.message}
        />
      )}
      <AlertDialog
        data-qaid='otype_alertOtype'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </div>
  );
};

export default OrderTypesList;
