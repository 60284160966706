import React, { useState, useContext } from "react";
import { Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import MyContext from "../../../Provider/MyContext";
import { H3, AvenirBlackH4, H5 } from "../../../utils/text";
import {
  CirclePluse as CirclePluseIcon,
  InfoOutlined,
} from "../../../components/SVG";
// import InfoOutlinedIcon from "../../../components/SVG/InfoOutlined";
import { validateAlphaNumeric } from "../../../utils/util";
import {
  ModifierMinMaxSelect,
  ModifierSetQuantityLimit,
  ModifierItem,
  RightDrawerTitleButtons,
  InputText,
  DraggableList,
  AlertDialog,
} from "../../../components";
import { useModifierGroup } from "../../../custom-hooks";

const useStyles = makeStyles({
  customToolTip: {
    backgroundColor: "#051D33",
    color: "#FFF",
    fontSize: "12px",
    fontWeight: 300,
    fontFamily: "AvenirLT",
  },
});

const ModifierGroupCreateEdit = ({
  open,
  initialValues = null,
  handleCreate = () => {},
  handleEdit = () => {},
  handleDelete = () => {},
  onClose,
  callBack,
  backButtonText,
}) => {
  const {
    modifierGroup,
    isEditing,
    errors,
    updateModifierGroup,
    updateAddonItem,
    addAddonItem,
    removeAddonItem,
    validateModifierGroup,
  } = useModifierGroup(initialValues);

  const classes = useStyles();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const Context = useContext(MyContext);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const handleMaxCheckboxChange = (e) => {
    updateModifierGroup("set_max_selection", e);
  };

  const handleLimitCheckboxChange = (e) => {
    updateModifierGroup("set_quantity_limit", e);
  };

  const handleMaxValueChange = (e) => {
    updateModifierGroup("maximum_select", e);
  };

  const handleCreateModifier = () => {
    if (validateModifierGroup()) handleCreate(modifierGroup);
  };
  const handleEditModifier = () => {
    if (validateModifierGroup()) handleEdit(modifierGroup, callBack);
  };
  const handleDeleteModifier = (e) => {
    if (e) {
      handleDelete(modifierGroup);
    } else {
      setShowDeleteDialog(false);
    }
  };

  const handleAlertCloseOpen = (value) => {
    setShowDeleteDialog(value);
  };
  const handleDragEnd = (startIndex, endIndex) => {
    const reorderedItems = Array.from(modifierGroup?.addon_items);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    const finalReorder = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
    }));
    updateModifierGroup("addon_items", finalReorder);
  };

  const renderItem = (item, index) => (
    <ModifierItem
      key={index}
      addonItemName={item.addon_item_name}
      basePrice={item.base_price}
      handleDeleteItem={() => removeAddonItem(index)}
      onItemNameChange={(value) =>
        updateAddonItem(index, "addon_item_name", value)
      }
      onPriceChange={(value) => updateAddonItem(index, "base_price", value)}
      errorModifierName={errors[`addon_item_name_${index}`]}
      errorBasePrice={errors[`base_price_${index}`]}
    />
  );
  return (
    <RightDrawerTitleButtons
      open={open}
      onClose={onClose}
      isEditing={isEditing}
      title={
        isEditing
          ? Context.langData.edit_modifier_group
          : Context.langData.create_modifier_group
      }
      deleteMessage={Context.langData.delete_modifier_group_body}
      deleteTitle={Context.langData.delete_modifier_group}
      alertOpenClose={showDeleteDialog}
      handleCreate={handleCreateModifier}
      handleEdit={() => setOpenUpdateDialog(true)}
      handleDelete={handleDeleteModifier}
      handleAlertCloseOpen={handleAlertCloseOpen}
      saveButton={Context.langData.update_modifier_group}
      backButton={backButtonText}>
      <Grid
        style={{
          marginLeft: 56,
          marginRight: 56,
          width: 500,
        }}>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            borderBottom: "1px solid #EEEEEE",
          }}>
          <H3
            data-qaid='createaddon_valuelabel'
            label={Context.langData.modifier_details}
            style={{ marginBottom: 10 }}
          />
        </Grid>
        <Grid style={{ paddingTop: 20 }}>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <AvenirBlackH4
              data-qaid='createaddon_valuelabel'
              label={Context.langData.modifier_group_name}
            />
            <InputText
              value={modifierGroup?.addon_cat_name}
              error={errors.addon_cat_name}
              onChange={(val) =>
                updateModifierGroup(
                  "addon_cat_name",
                  validateAlphaNumeric(val.target.value)
                )
              }
              maxLength={64}
              id='addon_cat_name'
            />
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              borderBottom: "1px solid #EEEEEE",
            }}>
            <H3
              data-qaid='createaddon_valuelabel'
              label={Context.langData.modifier}
              style={{ marginBottom: 10 }}
            />
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
            }}>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                width: "80%",
                justifyContent: "space-between",
                padding: "10px 0px",
              }}>
              <Grid style={{ width: "50%", marginLeft: 40 }}>
                <AvenirBlackH4 label={Context.langData.name} />
              </Grid>
              <Grid style={{ width: "30%" }}>
                <AvenirBlackH4 label={Context.langData.price} />
              </Grid>
            </Grid>
            {modifierGroup?.addon_items && (
              <DraggableList
                items={modifierGroup?.addon_items}
                onDragEnd={handleDragEnd}
                renderItem={renderItem}
                isEditing
                gridItemProps={{
                  display: "flex",
                  flexDirection: "column",
                }}
                position='position'
              />
            )}
            {errors.addon_items && (
              <Grid
                style={{
                  color: "#AB1212",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 0,
                  marginTop: 8,
                }}>
                <InfoOutlined
                  width={16}
                  height={16}
                  color='#AB1212'
                  style={{ marginRight: 6 }}
                />
                <H5 label={errors.addon_items} style={{ color: "#AB1212 " }} />
              </Grid>
            )}
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 15,
                cursor: "pointer",
                width: "100%",
                justifyContent: "center",
                padding: "20px 0px",
              }}
              onClick={addAddonItem}>
              <CirclePluseIcon
                data-qaid='createaddon-CirclePluseIcon'
                color='#007191'
                style={{ marginRight: 10 }}
              />
              <AvenirBlackH4
                data-qaid='createaddon__add'
                label={Context.langData.add_modifier_item}
                style={{ color: "#007191" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              borderBottom: "1px solid #EEEEEE",
            }}>
            <H3
              data-qaid='modify_group_settingslabel'
              label={Context.langData.modifier_group_settings}
              style={{ marginBottom: 10 }}
            />
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              alignItems: "center",
            }}>
            <AvenirBlackH4
              data-qaid='total_number_of_selectionlabel'
              label={Context.langData.total_number_of_selections}
            />
            <Tooltip
              title={Context.langData.total_number_of_selections_comment}
              classes={{ tooltip: classes.customToolTip }}>
              <IconButton>
                <InfoOutlined style={{ height: 15, width: 15 }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <ModifierMinMaxSelect
            checked={modifierGroup?.set_max_selection}
            initialCountOfMin={
              modifierGroup.minimum_select >= 1
                ? modifierGroup.minimum_select
                : 0
            }
            initialCountOFmax={
              modifierGroup.maximum_select >= 1
                ? modifierGroup.maximum_select
                : 1
            }
            minOfMin={0}
            maxOfMin={modifierGroup?.addon_items?.length || 1}
            minOfMax={
              modifierGroup.minimum_select > 1
                ? modifierGroup.minimum_select
                : 1
            }
            maxOfMax={50}
            multiSelectMax={50}
            onCheckboxChange={(value) => handleMaxCheckboxChange(value)}
            onMinChange={(value) =>
              updateModifierGroup("minimum_select", value)
            }
            onMaxChange={(value) => handleMaxValueChange(value)}
          />
          <ModifierSetQuantityLimit
            checked={modifierGroup.set_quantity_limit}
            checkBoxLabel={Context.langData.set_quantity_limit}
            initialCountOfMax={
              modifierGroup.maximum_quantity > 1
                ? modifierGroup.maximum_quantity
                : 1
            }
            minOfMax={1}
            maxOfMax={modifierGroup.maximum_select}
            onCheckboxChange={(value) => handleLimitCheckboxChange(value)}
            onMaxChange={(value) =>
              updateModifierGroup("maximum_quantity", value)
            }
            ModifierSetQuantityLimitDisable={!modifierGroup.set_max_selection}
            showCommentInTooltip
            multiSelectMax={
              modifierGroup.set_max_selection &&
              modifierGroup.set_quantity_limit
                ? modifierGroup.maximum_select
                : 10
            }
          />
        </Grid>
        <AlertDialog
          open={openUpdateDialog}
          onClose={() => setOpenUpdateDialog(false)}
          title={`${Context.langData.update_modifier_group_of_all_items}`}
          bodyText={Context.langData.changes_made_here_applied_to_all_items}
          secondaryButtonText={Context.langData.no_continue_editing}
          primaryButtonText={Context.langData.yes_update_all}
          onPrimaryAction={handleEditModifier}
          onSecondaryAction={() => {
            setOpenUpdateDialog(false);
          }}
        />
      </Grid>
    </RightDrawerTitleButtons>
  );
};

export default ModifierGroupCreateEdit;
