import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoCompliteModifier = ({ options, onChange }) => {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.addon_cat_name || ""}
      id='controllable-states-demo'
      style={{ width: "100%" }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label=''
          variant='outlined'
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

export default React.memo(AutoCompliteModifier);
