import React, { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { H6, AvenirBlackH4 } from "../../utils/text";
import { FeatureKeys } from "../../enums";

import MyContext from "../../Provider/MyContext";
import { fetchAllStocks } from "../../redux/actions";
import NoPermissionPage from "../../components/NoPermission";

const Inventory = () => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const featureFlagClient = useLDClient();
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  let inventoryFlagValue = false;
  if (featureFlagClient && selectedRestaurant && selectedRestaurant.rest_id) {
    const context = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(context, null, () => {});

    inventoryFlagValue = featureFlagClient.variation(
      FeatureKeys.Inventory,
      true
    );
  }
  const inventorylist = [
    {
      key: "vendors",
      title: Context.langData.vendors,
      id: 0,
      route: "inventory/vendors",
    },
    {
      key: "store_inventory",
      title: Context.langData.store,
      id: 1,
      route: "inventory/store",
    },
    {
      key: "kitchen_inventory",
      title: Context.langData.kitchens,
      id: 2,
      route: "inventory/kitchens",
    },
    {
      key: "recipes",
      title: Context.langData.recipe,
      id: 3,
      route: "inventory/recipe",
    },
  ];

  useEffect(() => {
    dispatch(fetchAllStocks());
    const item = inventorylist.find((item) =>
      location.pathname.includes(item.route)
    );
    if (item) {
      setSelectedIndex(item.id);
    } else if (location.pathname.endsWith("inventory")) {
      handleNavigation(inventorylist[0]);
    }
  }, []);

  const buttonArray = useMemo(() => {
    const { updatedButtons, buttonCount } = inventorylist.reduce(
      (acc, item) => {
        const permission = userPermissions.find((e) => e.key === item.key);
        const isPermission = permission ? permission.status : false;
        acc.updatedButtons.push({ ...item, isPermission });
        if (isPermission) acc.buttonCount += 1;
        return acc;
      },
      { updatedButtons: [], buttonCount: 0 }
    );
    return buttonCount > 0 ? updatedButtons : [];
  }, [userPermissions, inventorylist]);

  const handleNavigation = (item) => {
    setSelectedIndex(item.id);
    navigate(`/home/${item.route}`);
  };

  const isItemHasPermission = (idx) => {
    if (buttonArray.length > 0) {
      return buttonArray[idx].isPermission;
    }
    return false;
  };

  return (
    <Grid
      data-qaid='inventory_index_grid'
      style={{ backgroundColor: "#FFFFFF", minHeight: "100%", width: "100%" }}>
      <Grid data-qaid='inventory_index_contentgrid' style={{ height: "20%" }}>
        <Grid
          data-qaid='inventory_index_titlegrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid='inventory_index_title_label'
            label={Context.langData.inventory}
            style={{
              color: "#051D33",
              paddingTop: 36,
              fontWeigth: "800",
            }}
          />
        </Grid>
        <Grid
          data-qaid='inventory_index_bodygrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36, marginTop: 36, flexDirextion: "row" }}>
          {buttonArray.map((item, i) => (
            <Button
              key={i}
              data-qaid='inventory_button'
              style={{
                backgroundColor:
                  selectedIndex === item.id ? "#051D33" : "#EEEEEE",
                marginRight: 1,
                borderRadius: 0,
                borderTopLeftRadius: i === 0 ? 4 : 0,
                borderBottomLeftRadius: i === 0 ? 4 : 0,
                borderTopRightRadius: buttonArray.length - 1 === i ? 4 : 0,
                borderBottomRightRadius: buttonArray.length - 1 === i ? 4 : 0,
              }}
              onClick={() => handleNavigation(item)}>
              <AvenirBlackH4
                data-qaid='inventory_button_label'
                label={item.title}
                style={{
                  color: selectedIndex === item.id ? "#FFFFFF" : "#051D33",
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
        <Grid style={{ borderTop: "1px solid #EEE", marginTop: 20 }} />
      </Grid>
      <Grid data-qaid='inventory_body_view' style={{ height: "80%" }}>
        {selectedIndex !== -1 &&
        isItemHasPermission(selectedIndex) &&
        inventoryFlagValue ? (
          <Outlet />
        ) : (
          <NoPermissionPage />
        )}
      </Grid>
    </Grid>
  );
};

export default Inventory;
