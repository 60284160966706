const colors = {
  backgroundColor: "white",
  buttonColor: "#051D33",
  yellowBtnColor: "#FFB600",
  blueBtnColor: "#00B6C9",
  navyText: "#051D33",
  redText: "#8b0000",
  whiteText: "#ffffff",
  greyText: "#D3D3D3",
  darkgreyText: "#A9A9A9",
  iconColor: "#004C60",
  borderColor: "#707272",
  skyDarkest: "#004C60",
};

export default colors;
