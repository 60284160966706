import { Grid, InputAdornment } from "@material-ui/core";
import {
  ConvertPrice,
  validateAlphaNumeric,
  validatePositiveFloatValue,
} from "../utils/util";
import { MenuIconTwoLines, Delete } from "./SVG";
import { InputText, InputNumber } from ".";

const ModifierItem = ({
  addonItemName,
  basePrice,
  onItemNameChange,
  onPriceChange,
  handleDeleteItem,
  key,
  errorModifierName,
  errorBasePrice,
}) => {
  const handleItemName = (e) => {
    onItemNameChange(validateAlphaNumeric(e.target.value));
  };

  const handleOnBlurPrice = (e) => {
    onPriceChange(ConvertPrice(e.target.value));
  };

  const handleBasePrice = (e) => {
    onPriceChange(validatePositiveFloatValue(e.target.value));
  };
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}>
      <Grid
        data-qaid='modifiers_input_field'
        container
        key={key}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 0,
        }}>
        <Grid style={{ marginTop: 5 }}>
          <MenuIconTwoLines />
        </Grid>
        <Grid style={{ width: "50%" }}>
          <InputText
            value={addonItemName}
            maxLength={64}
            onChange={handleItemName}
            height={40}
            inputPlaceholder='Modifier name'
            error={errorModifierName}
          />
        </Grid>
        <Grid style={{ width: "30%" }}>
          <InputNumber
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            value={basePrice}
            maxLength={64}
            onChange={handleBasePrice}
            onBlur={handleOnBlurPrice}
            height={40}
            inputPlaceholder='1.00'
            error={errorBasePrice}
            type='number'
          />
        </Grid>
        <Grid onClick={handleDeleteItem} style={{ marginTop: 5 }}>
          <Delete />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModifierItem;
