import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { H4 } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { CustomCheckbox, ModifierCounter } from ".";

const ModifierMinMaxSelect = ({
  checked,
  initialCountOfMin,
  initialCountOFmax,
  minOfMin,
  maxOfMin,
  minOfMax,
  maxOfMax,
  onCheckboxChange,
  onMinChange,
  onMaxChange,
  multiSelectMax,
}) => {
  const { langData } = useContext(MyContext);
  // const [maxSelectionChecked, setMaxSelectionChecked] = useState(checked);

  const handleMinChange = (value) => {
    onMinChange(value);
  };

  const handleMaxChange = (value) => {
    onMaxChange(value);
  };

  const handleCheckboxChange = (checked) => {
    // setMaxSelectionChecked(checked);
    onCheckboxChange(checked);
  };

  return (
    <Grid
      container
      direction='column'
      style={{ width: "400px", marginTop: 20, marginBottom: 30 }}>
      <Grid
        item
        container
        direction='column'
        alignItems='flex-start'
        style={{ marginLeft: 10, marginRight: 10, width: "100%" }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "nowrap",
              width: "100%",
              border: "1px ",
            }}>
            <Grid
              style={{
                width: "50%",
                marginLeft: 4,
                marginRight: 4,
                border: "1px ",
              }}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                  marginTop: 5,
                }}>
                <H4 label={langData.minimum} style={{ textAlign: "center" }} />
              </Grid>
              <ModifierCounter
                initialCount={initialCountOfMin}
                onChange={handleMinChange}
                max={maxOfMin}
                min={minOfMin}
                multiSelectMax={multiSelectMax}
              />
            </Grid>
            <Grid
              style={{
                width: "50%",
                marginLeft: 4,
                marginRight: 4,
                border: "1px ",
              }}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                  marginTop: 5,
                }}>
                <CustomCheckbox
                  checked={checked}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                />
                <H4 label={langData.maximum} />
              </Grid>
              <ModifierCounter
                initialCount={initialCountOFmax}
                onChange={handleMaxChange}
                max={maxOfMax}
                min={minOfMax}
                multiSelectMax={multiSelectMax}
                ModifierCounterDisable={!checked}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModifierMinMaxSelect;
