import React from "react";
import clsx from "clsx";
import { ListItemText, ListItem, makeStyles } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "AvenirLT",
    fontSize: 14,
    fontWeight: 400,
    transition: "font-weight 0.2s ease-in-out",
    "&:hover": { fontWeight: 900 },
    "&.active": { fontWeight: 900 },
  },
  subtitle: {
    fontFamily: "AvenirLT",
    fontSize: 12,
    fontWeight: 400,
    color: "#004C60",
  },

  subBtn: {
    fontFamily: "AvenirLT",
    height: 50,
    margin: 5,
    transition: "all 0.3s ease",
    "&:hover $title": { fontWeight: 900 },
    "&:hover": {
      backgroundColor: "#C8E6F1",
      fontWeight: "bold",
      fontSize: "1.2em",
      borderRadius: "4px",
      width: "97%",
    },
    "&.active": {
      backgroundColor: "#C8E6F1",
      fontWeight: "bold",
      fontSize: "1.2em",
      borderRadius: "4px",
      width: "97%",
    },
  },
}));
const SubRouteNavItem = ({ id, href, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = href.split("/");
  const classes = useStyles();
  const isActiveSubRoute = location.pathname.endsWith(path[path.length - 1]);
  return (
    <ListItem
      key={id}
      button
      className={clsx(classes.subBtn, { active: isActiveSubRoute })}
      onClick={() => navigate(href)}
      style={{ padding: "8px 16px" }}>
      <ListItemText
        primary={
          <span className={clsx(classes.title, { active: isActiveSubRoute })}>
            {title}
          </span>
        }
      />
    </ListItem>
  );
};

export default SubRouteNavItem;
