import React, { useContext, useMemo } from "react";
import { Grid, Button } from "@material-ui/core";
import { AvenirBlackH4, H6 } from "../utils/text";
import styles from "../styles/index";
import MyContext from "../Provider/MyContext";
import { CirclePluse } from "./SVG";

const NoData = ({ page, callback }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const getPlural = (name) => {
    if (name === "category") {
      return "categories";
    }
    if (name === "addon category") {
      return "addon categories";
    }
    if (name === "dietary restriction") {
      return "dietary restrictions";
    }
    return `${name}s`;
  };
  const title1 = useMemo(
    () =>
      `${Context.langData.no_record} ${getPlural(page)}. ${Context.langData.to_add_your_first} ${page},`,
    [page]
  );
  const title2 = useMemo(
    () =>
      `${Context.langData.please_click} "${Context.langData.create.toLowerCase()} ${page}" ${Context.langData.button_below}`,
    [page]
  );
  return (
    <Grid
      data-qaid='customers_labelView'
      item
      lg={12}
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: "10%",
        flexDirection: "column",
      }}>
      <H6 label={title1} style={{ textAlign: "center" }} />
      <H6 label={title2} style={{ textAlign: "center" }} />
      <Grid item style={{ marginTop: 30 }}>
        <Button
          className={style.buttonClass}
          data-qaid='customers_buttonView'
          style={{ backgroundColor: "#FFB600", color: "white", height: 40 }}
          variant='contained'
          id='cancel'
          onClick={callback}>
          <CirclePluse data-qaid='customers_createpluse' />
          <AvenirBlackH4
            data-qaid='customers_createlabel'
            label={`${Context.langData.create} ${page}`}
            style={{ paddingLeft: 5 }}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoData;
