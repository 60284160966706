import * as React from "react";

const PlusStandardIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.10417 10C4.93158 10 4.79167 9.86009 4.79167 9.6875L4.79167 0.3125C4.79167 0.139911 4.93158 -7.5441e-09 5.10417 0C5.27676 7.5441e-09 5.41667 0.139911 5.41667 0.3125L5.41667 9.6875C5.41667 9.86009 5.27676 10 5.10417 10Z'
      fill='#007191'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 5.10417C1.50882e-08 4.93158 0.139911 4.79167 0.3125 4.79167L9.6875 4.79167C9.86009 4.79167 10 4.93158 10 5.10417C10 5.27676 9.86009 5.41667 9.6875 5.41667L0.3125 5.41667C0.139911 5.41667 -1.50882e-08 5.27676 0 5.10417Z'
      fill='#007191'
    />
  </svg>
);

export default PlusStandardIcon;
