import React from "react";
import { Grid } from "@material-ui/core";
import { H5, H6 } from "../utils/text";

const TitleWrapper = ({ children, mainTitle, subTitle }) => (
  <Grid
    data-qaid='item_catalogue_grid'
    container
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 30,
      borderBottom: "1px solid #EEEEEE",
    }}>
    <Grid
      style={{
        height: "20%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      <H5
        label={mainTitle}
        style={{
          color: "#007191",
          fontWeight: "500",
          fontSize: "10px",
        }}
      />
      <H6
        label={subTitle}
        style={{
          color: "#051D33",
          fontWeight: "800",
        }}
      />
    </Grid>
    <Grid style={{ marginTop: 10 }}> {children}</Grid>
  </Grid>
);

export default TitleWrapper;
