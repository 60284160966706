import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import VerticalTabs from "../../../components/verticalTabs";
import MyContext from "../../../Provider/MyContext";
import { MainBodyWrapper, TitleWrapper } from "../../../components";
import EndOfDayReports from "./endOfDayReports";
import UserReports from "./userReports";

const EmployeeReports = () => {
  const Context = useContext(MyContext);
  const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
  const callback = (value) => {
    setSelectIndexTabChange(value);
  };
  useEffect(() => {}, []);
  const Tabs = [
    { name: Context.langData.end_day_report, id: 0 },
    { name: Context.langData.user_report, id: 1 },
  ];
  return (
    <MainBodyWrapper data-qaid='employeeReport_index_card_content'>
      <TitleWrapper
        mainTitle={Context.langData.reports}
        subTitle={Context.langData.employee_reports}
      />

      <Grid data-qaid='employeeReport_index_grid' container />

      <Grid data-qaid='employeeReport_index_body_grid' container>
        <Grid
          data-qaid='employeeReport_index_tab_grid'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <VerticalTabs
            data-qaid='employeeReport_index_tabs'
            tabData={Tabs}
            indexCallback={callback}
          />
        </Grid>
        <Grid
          data-qaid='employeeReport_index_content_grid'
          item
          xs={12}
          lg={10}
          style={{ paddingLeft: "10px" }}>
          {selectIndexTabChange === 0 && (
            <EndOfDayReports data-qaid='employeeReport_index_list' />
          )}
          {selectIndexTabChange === 1 && (
            <UserReports data-qaid='userReport_index_list' />
          )}
        </Grid>
      </Grid>
    </MainBodyWrapper>
  );
};

export default EmployeeReports;
