import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import {
  editDietaryAttribute,
  deleteDietaryAttribute,
  fetchDietaryAttributes,
  createDietaryAttribute,
} from "../../../redux/actions";
import InputText from "../../../components/InputText";
import MyContext from "../../../Provider/MyContext";
import CirclePluse from "../../../components/SVG/PluseIcon";
import DeleteIcon from "../../../components/SVG/Delete";
import { AvenirBlackH4, H8, H5, H6 } from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import CircleClose from "../../../components/SVG/CloseCircle";
import SnackBar from "../../../components/SnackBar";
import styles from "../../../styles/index";
import NoData from "../../../components/NoData";
import SimpleTable from "../../../components/SimpleTable";
import BackIcon from "../../../components/SVG/Back";
import AlertDialog from "../../../components/Dialog";
import { validateAlphaNumeric } from "../../../utils/util";

const DietaryAttribute = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const DietaryAttributes = useSelector(
    (state) => state.menu.dietary_attributes
  );
  // eslint-disable-next-line no-unused-vars
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openDietaryAttributeDrawer, toggleCreateDietaryAttributeDrawer] =
    useState(false);
  const [openEditDietaryAttribute, toggleEditDietaryAttributeDrawer] =
    useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pagenumber, setPagenumber] = useState(0);
  const [selectedTabData, setSelectedTabData] = useState([]);
  const [columns, setColumns] = useState([
    {
      field: "name",
      display: Context.langData.dietary_restriction_name,
      sort: false,
      size: "100%",
    },
  ]);
  useEffect(() => {
    setSelectedTabData(DietaryAttributes);
  }, [DietaryAttributes]);
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  function CommonCallback() {
    toggleCreateDietaryAttributeDrawer(false);
    toggleEditDietaryAttributeDrawer(false);
    dispatch(fetchDietaryAttributes());
  }
  function CreateDietaryAttributeCallback(isSuuscess) {
    CommonCallback();
    if (isSuuscess) {
      setSnackbar({
        message: Context.langData.dietary_restriction_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.dietary_restriction_create_failed,
        severity: "error",
      });
    }
  }
  function editDietaryAttributeCallback(isSuuscess) {
    CommonCallback();
    if (isSuuscess) {
      setSnackbar({
        message: Context.langData.dietary_restriction_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.dietary_restriction_edit_failed,
        severity: "error",
      });
    }
  }
  function deleteDietaryAttributeCallback(isSuuscess) {
    CommonCallback();
    if (isSuuscess) {
      setSnackbar({
        message: Context.langData.dietary_restriction_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.dietary_restriction_edit_failed,
        severity: "error",
      });
    }
  }

  const onPageChange = (value) => {
    setPagenumber(value);
  };
  const sortingOnClick = (name, desc, index) => {
    const data = [...columns];
    data[index] = { ...data[index], sort: !desc };
    setColumns(data);
    const sortAddons = _.orderBy(
      selectedTabData,
      [name],
      [desc ? "desc" : "asc"]
    );
    setSelectedTabData(sortAddons);
  };
  const onRowClick = (rowValue) => {
    setRow(rowValue);
    toggleEditDietaryAttributeDrawer(true);
  };
  return (
    <Grid
      data-qaid='dietary_attribute_main_grid'
      container
      justifyContent='flex-start'
      style={{ padding: 30 }}>
      {selectedTabData.length > 0 ? (
        <>
          <Grid
            data-qaid='dietary_attribute_grid'
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          />

          <Grid
            data-qaid='dietary_attribute_grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: 25,
              paddingRight: 15,
            }}>
            <Grid
              style={{
                height: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <H5
                label={Context.langData.menu}
                style={{
                  color: "#007191",
                  fontWeight: "500",
                  fontSize: "10px",
                }}
              />
              <H6
                label={Context.langData.dietary_restrictions}
                style={{
                  color: "#051D33",
                  fontWeight: "800",
                }}
              />
            </Grid>
            <Button
              className={style.buttonClass}
              data-qaid='dietary_attribute_creategrid'
              style={{
                backgroundColor: "#FFB600",
                color: "white",
              }}
              variant='contained'
              id='cancel'
              onClick={() => toggleCreateDietaryAttributeDrawer(true)}>
              <CirclePluse data-qaid='dietary_attribute_createpluse' />
              <AvenirBlackH4
                data-qaid='dietary_attribute_createlabel'
                label={Context.langData.create_dietary_restriction}
                style={{ paddingLeft: 5 }}
              />
            </Button>
          </Grid>
          <Grid
            data-qaid='categories_body_grid'
            item
            xs={12}
            style={{
              width: "100%",
            }}>
            <SimpleTable
              data-qaid='dietary_attribute_simpleTable'
              columns={columns}
              rows={selectedTabData}
              sortingOnClick={sortingOnClick}
              onPageChange={onPageChange}
              onRowClick={onRowClick}
            />
          </Grid>
        </>
      ) : (
        <NoData
          page='dietary restriction'
          callback={() => toggleCreateDietaryAttributeDrawer(true)}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='addon_dietary_attribute_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {openDietaryAttributeDrawer && (
        <CreateDietaryAttribute
          data-qaid='addon_create_dietary_attribute_snackbar'
          open={openDietaryAttributeDrawer}
          onClose={() => toggleCreateDietaryAttributeDrawer(false)}
          // eslint-disable-next-line react/jsx-no-bind
          callback={CreateDietaryAttributeCallback}
        />
      )}
      {openEditDietaryAttribute && (
        <EditDietaryAttribute
          data-qaid='addon_create_dietary_attribute_snackbar'
          open={openEditDietaryAttribute}
          row={row}
          onClose={() => toggleEditDietaryAttributeDrawer(false)}
          // eslint-disable-next-line react/jsx-no-bind
          callback={editDietaryAttributeCallback}
          // eslint-disable-next-line react/jsx-no-bind
          deleteCallback={deleteDietaryAttributeCallback}
        />
      )}
    </Grid>
  );
};
const CreateDietaryAttribute = ({ open, callback, onClose }) => {
  const style = styles();
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const save = () => {
    if (name.trim()) {
      dispatch(
        createDietaryAttribute(
          {
            name: name.trim(),
          },
          callback
        )
      );
    } else {
      setError(Context.langData.no_text_error);
    }
  };

  const setNameValue = (val) => {
    setError("");
    setName(val);
  };
  return (
    <RightDrawer open={open} padding={1} onClose={onClose}>
      <Grid
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          style={{
            display: "flex",
            height: "85%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            data-qaid='createcategory_close_grid'
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <CircleClose
              data-qaid='createcategory_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='createcategory_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}
            onClick={onClose}>
            <BackIcon
              data-qaid='dietary_attribute_editBack_icon'
              onClick={() => onClose()}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <H8
            data-qaid='createcategory_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.create_dietary_restriction}
          />

          <Grid style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
            <Grid
              container
              style={{ display: "flex", flexDirection: "column" }}>
              <AvenirBlackH4
                data-qaid='createDietaryAttribute_valuelabel'
                label={Context.langData.restriction_name}
              />
              <InputText
                value={name}
                error={error}
                maxLength={64}
                onChange={(val) =>
                  setNameValue(validateAlphaNumeric(val.target.value))
                }
                id='name'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid
            data-qaid='createDietaryAttribute_action_grid'
            container
            style={{ backgroundColor: "#FAFAFA" }}>
            <Grid
              data-qaid='createDietaryAttribute_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='createDietaryAttribute_cancel_button'
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createDietaryAttribute_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createDietaryAttribute_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='createDietaryAttribute_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};
const EditDietaryAttribute = ({
  open,
  callback,
  row,
  onClose,
  deleteCallback,
}) => {
  const style = styles();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [showDeleteDialog, toggleDeleteDialog] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setData(row);
  }, [row]);
  const save = () => {
    if (data.name) {
      dispatch(editDietaryAttribute(data, callback));
    } else {
      setError(Context.langData.no_text_error);
    }
  };
  const setCatName = (val) => {
    setError();
    const responseData = { ...data, name: val };
    setData(responseData);
  };
  const handleDeleteDietaryAttribute = () => {
    dispatch(
      deleteDietaryAttribute([data.dietary_attribute_id], deleteCallback)
    );
  };
  const handleDeleteClose = (e) => {
    if (e) {
      handleDeleteDietaryAttribute();
    } else {
      toggleDeleteDialog(false);
    }
  };
  return (
    <RightDrawer open={open} onClose={onClose} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='createcategory_close_grid'
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <CircleClose
                data-qaid='createcategory_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='createcategory_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}
              onClick={onClose}>
              <BackIcon
                data-qaid='dietary_attribute_editBack_icon'
                onClick={() => onClose()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
            <H8
              data-qaid='createcategory_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.edit_dietary_restriction}
            />
            <Grid style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
              <Grid
                container
                style={{ display: "flex", flexDirection: "column" }}>
                <AvenirBlackH4
                  data-qaid='createDietaryAttribute_valuelabel'
                  label={Context.langData.restriction_name}
                />
                <InputText
                  value={data.name}
                  error={error}
                  maxLength={64}
                  onChange={(val) =>
                    setCatName(validateAlphaNumeric(val.target.value))
                  }
                  id='name'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createDietaryAttribute_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='createDietaryAttribute_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  // justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Grid container wrap='nowrap' style={{ width: "100%" }}>
                  <Grid container>
                    <Button
                      data-qaid='createDietaryAttribute_cancel_button'
                      onClick={() => toggleDeleteDialog(true)}
                      variant='text'
                      style={{ marginLeft: 45 }}>
                      <DeleteIcon width={25} height={25} color='#8b0000' />
                      <AvenirBlackH4
                        data-qaid='createDietaryAttribute_delete_text'
                        label={Context.langData.delete}
                        style={{ marginLeft: 10, color: "#8b0000" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Button
                  className={style.buttonClass}
                  data-qaid='createDietaryAttribute_cancel_button'
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    height: 40,
                    width: 103,
                    border: "2px solid #051D33",
                  }}>
                  <AvenirBlackH4
                    data-qaid='createDietaryAttribute_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='createDietaryAttribute_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid='createDietaryAttribute_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showDeleteDialog && (
          <AlertDialog
            showDialog={showDeleteDialog}
            body='Are you sure you want to delete this dietary restriction?'
            heading='Delete dietary restriction'
            IsError={false}
            showExtra={false}
            handleClose={handleDeleteClose}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default DietaryAttribute;
