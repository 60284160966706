import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import styles from "../../../../styles/index";
import InputText from "../../../../components/InputText";
import { H4, AvenirBlackH4, H8, TBold, T } from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import CloseCircle from "../../../../components/SVG/CloseCircle";
import SettingsIcon from "../../../../components/SVG/Settings";
import RightDrawer from "../../../../components/RightDrawer";
import Message from "../../../../components/Message";
import SnackBar from "../../../../components/SnackBar";
import { createTerminal, fetchTermial } from "../../../../redux/actions";
import {
  ImportantReminder,
  MainBodyWrapper,
  TitleWrapper,
} from "../../../../components";
import TerminalHeader from "./terminalHeader";

import TerminalDataTable from "./TerminalDataTable";
import EditTerminal from "./editTerminal";

const TerminalsList = () => {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const terminalsData = useSelector((state) => state.settingsReducer.terminals);
  const [terminalItems, setTerminalItems] = useState([]);
  const [showCreateTerminal, setShowCreateTerminal] = useState(false);
  const [showEditTerminal, setShowEditTerminal] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [row, setRow] = useState({});
  const [columns, setColumns] = useState([
    {
      field: "moneris_terminal_id",
      headerName: Context.langData.terminal_ID,
      sort: false,
    },
    { field: "name", headerName: Context.langData.name, sort: false },
    {
      field: "paired_device_name",
      headerName: Context.langData.pairing,
      sort: false,
    },
    { field: "status" },
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _data = [...terminalsData];
    if (searchText) {
      const filterSearchData = _data.filter((e) =>
        e.moneris_terminal_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setTerminalItems(filterSearchData);
    } else {
      _data.map((item) => {
        if (item?.paired_device_name == null) {
          item.paired_device_name = "-";
        } else {
          // eslint-disable-next-line no-self-assign
          item.paired_device_name = item.paired_device_name;
        }
      });
      setTerminalItems(_data);
    }
  }, [terminalsData, searchText]);

  const commonCallback = () => {
    dispatch(fetchTermial());
    setShowCreateTerminal(false);
    setShowEditTerminal(false);
  };

  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.terminal_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.terminal_create_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.terminal_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.terminal_edit_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.terminal_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.terminal_delete_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const disconnectCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.terminal_disconnect_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.terminal_disconnect_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortTerminalList = _.orderBy(
      terminalItems,
      [value],
      [sortValue ? "desc" : "asc"]
    );
    setTerminalItems(sortTerminalList);
  };

  const onClickView = (rowValue) => {
    setShowEditTerminal(true);
    setRow(rowValue);
  };

  return (
    <MainBodyWrapper>
      {terminalsData?.length > 0 ? (
        <>
          <TitleWrapper
            mainTitle={Context.langData.settings}
            subTitle={Context.langData.terminals}
          />
          <ImportantReminder
            message={Context.langData.terminal_support_message}
          />
          <TerminalHeader
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <TerminalDataTable
            data-qaid='items_recipeTable'
            columns={columns}
            rows={terminalItems}
            sortingOnClick={sortingOnClick}
            onClickView={onClickView}
            noRowData
            noData={Context.langData.no_data}
            disconnectCallback={disconnectCallback}
          />
        </>
      ) : (
        <Grid
          data-qaid='terminals_labelView'
          item
          lg={12}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: "10%",
            flexDirection: "column",
          }}>
          <Grid
            item
            style={{
              maxWidth: 410,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}>
            <TBold
              label={Context.langData.no_terminals_added}
              style={{ textAlign: "center", fontSize: 32 }}
            />
            <T
              label={Context.langData.please_add_terminal}
              style={{ marginTop: 20, fontSize: 18, color: "#051D33" }}
            />
          </Grid>
        </Grid>
      )}
      {showCreateTerminal && (
        <CreateTerminal
          open={showCreateTerminal}
          terminalItems={terminalItems}
          onClose={() => setShowCreateTerminal(false)}
          createCallback={createCallback}
        />
      )}
      {showEditTerminal && (
        <EditTerminal
          open={showEditTerminal}
          onClose={() => setShowEditTerminal(false)}
          editCallback={editCallback}
          row={row}
          terminalItems={terminalItems}
          deleteCallback={deleteCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </MainBodyWrapper>
  );
};

const CreateTerminal = ({ open, onClose, createCallback, terminalItems }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const msg = useState({ open: false, msg: "", severity: "success" });
  const [error, setError] = useState(null);
  const [terminalId, setTerminalId] = useState("");
  const [name, setName] = useState("");

  const handleName = (val) => {
    const upper = val.charAt(0).toUpperCase() + val.slice(1);
    setTerminalId(upper);
  };

  const handleAddTermial = () => {
    const index = terminalItems.find(
      (e) => e.moneris_terminal_id === terminalId
    );
    if (index) {
      setError(`Terminal ID already exists`);
    } else {
      const str1 = terminalId.substring(0, 1);
      const str2 = terminalId.substring(1, 8).split("");
      if (str1 === "A" && str2.length === 7) {
        const obj = {
          moneris_terminal_id: terminalId,
          name,
        };
        dispatch(createTerminal(obj, createCallback));
      } else {
        setError("Terminal ID must follow the structure “A1234567”");
      }
    }
  };

  return (
    <RightDrawer
      data-qaid='assigntaxescentermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <Grid
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          width: 600,
        }}>
        <Grid
          style={{
            display: "flex",
            height: "80%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            data-qaid='assigntaxesclose_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <CloseCircle
              data-qaid='assigntaxescloseicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='assigntaxessettings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <SettingsIcon
              data-qaid='assigntaxessetting_icon'
              style={{ marginRight: 10 }}
              width={16}
              height={16}
              color='#707272'
            />
            <H4
              data-qaid='assigntaxessettings'
              label={Context.langData.settings}
              style={{ color: "#707272" }}
            />
          </Grid>
          <H8
            data-qaid='assignlitchen_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.add_terminal}
          />
          <Grid
            data-qaid='createtaxe_main_grid'
            style={{
              marginLeft: 56,
              marginRight: 56,
              maxWidth: 500,
              marginBottom: 20,
            }}>
            <Grid
              data-qaid='createtaxe_child_grid'
              style={{ display: "flex", flexDirection: "column" }}>
              <Grid
                data-qaid='createtaxe_catgrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}>
                <AvenirBlackH4
                  data-qaid='createtaxe_catlabel'
                  label={Context.langData.terminal_ID}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  data-qaid='createtaxe_catinput'
                  value={`${terminalId}`}
                  error={error}
                  maxLength={8}
                  // type="number"
                  onChange={(val) =>
                    handleName(val.target.value.replace(/[^a-zA-Z0-9]/g, ""))
                  }
                  id='tax_category'
                />
              </Grid>
              <Grid
                data-qaid='createtaxe_comment_grid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 10,
                }}>
                <AvenirBlackH4
                  data-qaid='createtaxe_comlabel'
                  label={Context.langData.name_optional}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  data-qaid='createtaxe_comip'
                  value={name}
                  onChange={(val) => setName(val.target.value)}
                  id='comment'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "20%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid
            data-qaid='createtaxe_action_grid'
            container
            style={{
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
            }}>
            <Grid
              data-qaid='createtaxe_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
                alignItems: "center",
              }}>
              <Button
                className={style.buttonClass}
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  border: "2px solid #051D33",
                  height: 40,
                  width: 103,
                }}>
                <AvenirBlackH4
                  data-qaid='createtaxe_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createtaxe_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={handleAddTermial}>
                <AvenirBlackH4
                  data-qaid='createtaxe_save_text'
                  label={Context.langData.add}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Message open={msg.open} severity={msg.severity} msg={msg.msg} />
      </Grid>
    </RightDrawer>
  );
};

export default TerminalsList;
