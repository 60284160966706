import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchAllItems,
  fetchOrderTypes,
  fetchDietaryAttributes,
} from "../../redux/actions";
import Categories from "./items";
import OrderTypes from "./OrderTypes";

const Menu = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAllItems());
    dispatch(fetchOrderTypes());
    dispatch(fetchDietaryAttributes());
  }, [dispatch]);

  return (
    <Grid
      style={{
        backgroundColor: "#FFFFFF",
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
      }}>
      {/* Render Selected View */}
      <Grid style={{ height: "80%", width: "100%" }}>
        {location.pathname.includes("categories") ? (
          <Categories />
        ) : (
          <OrderTypes />
        )}
      </Grid>
    </Grid>
  );
};

export default Menu;
