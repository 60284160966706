import React, { useState, useContext } from "react";
import { Box, Button } from "@material-ui/core";
import { DottedText } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { InfoOutlined } from "./SVG";

const ImportantReminder = ({ message }) => {
  const [showMore, setShowMore] = useState(false);
  const Context = useContext(MyContext);
  const isLongText = message.length > 240;
  const truncatedText = isLongText
    ? ` ${message.substring(0, 240)}...`
    : message;

  return (
    <Box
      data-qaid='taxesList-blurb'
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 16,
        backgroundColor: "#F3FAFD",
        borderRadius: 4,
        maxWidth: "100%",
        flexWrap: "wrap",
        wordBreak: "break-word",
        overflowWrap: "break-word",
      }}>
      <InfoOutlined style={{ width: 24, height: 24, color: "#004C60" }} />
      <Box
        style={{
          paddingLeft: 8,
          flex: 1,
          minWidth: 0,
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}>
        {showMore ? (
          <Box
            data-qaid='taxesList-blurb-message'
            style={{
              color: "#051D33",
              fontSize: 14,
              fontFamily: "AvenirLT",
              lineHeight: 1.5,
              paddingTop: 4,
            }}>
            {message}
            {isLongText && (
              <Button
                onClick={() => setShowMore(false)}
                style={{
                  marginLeft: 8,
                  color: "#004C60",
                  padding: 0,
                  minWidth: "auto",
                  background: "none",
                }}>
                {Context.langData.show_less}
              </Button>
            )}
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              flexWrap: "wrap",
            }}>
            <Box
              data-qaid='taxesList-blurb-message'
              style={{
                flex: 1,
                minWidth: 0,
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}>
              <DottedText label={truncatedText} />
              {isLongText && (
                <Button
                  onClick={() => setShowMore(true)}
                  style={{
                    color: "#004C60",
                    padding: 0,
                    minWidth: "auto",
                    background: "none",
                  }}>
                  {Context.langData.read_more}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ImportantReminder;
