import * as React from "react";

const KebabMenu = React.memo(
  ({ width = 24, height = 24, color = "#007191" }) => (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_7999_81853)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.375 1.56494C11.3394 1.56494 10.5 2.40441 10.5 3.43994C10.5 4.47547 11.3394 5.31494 12.375 5.31494C13.4105 5.31494 14.25 4.47547 14.25 3.43994C14.25 2.40441 13.4105 1.56494 12.375 1.56494ZM9 3.43994C9 1.57598 10.511 0.0649414 12.375 0.0649414C14.2389 0.0649414 15.75 1.57598 15.75 3.43994C15.75 5.3039 14.2389 6.81494 12.375 6.81494C10.511 6.81494 9 5.3039 9 3.43994ZM12.375 10.1899C11.3394 10.1899 10.5 11.0294 10.5 12.0649C10.5 13.1005 11.3394 13.9399 12.375 13.9399C13.4105 13.9399 14.25 13.1005 14.25 12.0649C14.25 11.0294 13.4105 10.1899 12.375 10.1899ZM9 12.0649C9 10.201 10.511 8.68994 12.375 8.68994C14.2389 8.68994 15.75 10.201 15.75 12.0649C15.75 13.9289 14.2389 15.4399 12.375 15.4399C10.511 15.4399 9 13.9289 9 12.0649ZM12.375 18.8149C11.3394 18.8149 10.5 19.6544 10.5 20.6899C10.5 21.7255 11.3394 22.5649 12.375 22.5649C13.4105 22.5649 14.25 21.7255 14.25 20.6899C14.25 19.6544 13.4105 18.8149 12.375 18.8149ZM9 20.6899C9 18.826 10.511 17.3149 12.375 17.3149C14.2389 17.3149 15.75 18.826 15.75 20.6899C15.75 22.5539 14.2389 24.0649 12.375 24.0649C10.511 24.0649 9 22.5539 9 20.6899Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_7999_81853'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0 0.0649414)'
          />
        </clipPath>
      </defs>
    </svg>
  )
);

export default KebabMenu;
