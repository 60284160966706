import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { Footer } from "../../components";

import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("md")]: {
      // paddingLeft: 200
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    paddingBottom: 41,
  },
}));

const DashboardLayout = () => {
  const isDrawerOpen = useSelector((state) => state.dashboard.isDrawerOpen);
  const classes = useStyles(isDrawerOpen);
  const dispatch = useDispatch();
  const handleMobileOpen = (flag) => {
    dispatch({ type: "TOGGLE_NAVBAR", payload: flag });
  };

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={handleMobileOpen} mobileNavOpen={isDrawerOpen} />
      <NavBar openMobile={isDrawerOpen} />
      <Footer from='dashboard' />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
