import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  withStyles,
  FormHelperText,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import {
  RightDrawer,
  InputText,
  MyAccount as MyAccountDialog,
  SingleSelect,
  TextInputMask as InputMaskComponent,
  SnackBar,
} from "../../components";
import {
  CircleClose,
  HelpIcon,
  LogoutIcon,
  Return as ReturnIcon,
  User as UserIcon,
  ExclamationIcon,
  MonerisLogo,
  CloseCross,
} from "../../components/SVG";
import MyContext from "../../Provider/MyContext";
import { logoutUser, saveHelpSupport } from "../../redux/actions";
import StoreConfig from "../../redux/store";
import { AvenirBlackH4, H4, H6, H5 } from "../../utils/text";
import {
  ReadFromSession,
  RemoveFromSession,
} from "../../Provider/sessionStorageServices";
import { StorageKey } from "../../models/Constant";
import instance from "../../redux/actions/axiosInstance";
import urls from "../../urls";

const useStyles = makeStyles((theme) => ({
  root: { zIndex: 9999 },
  avatar: {
    width: 60,
    height: 60,
  },
  toolbar: {
    backgroundColor: "#051D33",
  },
  btn: {
    color: "#000",
  },
  menu: {
    paddingTop: theme.spacing(2),
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  mobileNavOpen,
  showUser = true,
  ...rest
}) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const email = useSelector((state) => state.loginReducer.loginDetails);
  const refreshToken = useSelector(
    (state) => state.loginReducer.loginDetails.refresh_token
  );
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openTestDialog, setOpenTestDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const isTablet = useMediaQuery("(max-width: 1366px)");

  const handleOpenHelp = () => {
    setOpenHelpDialog(true);
  };
  const handlecloseMyAccount = () => {
    setOpenDialog(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id) => {
    setAnchorEl(null);
    if (id === "logout") {
      const { store } = StoreConfig;
      dispatch(
        logoutUser(
          {
            oauth_token: store.getState().loginReducer.loginDetails.oauth_token,
          },
          logoutCallback
        )
      );
    }
  };
  const logoutCallback = () => {
    const rest_id = ReadFromSession(StorageKey.Rest_id);
    dispatch({ type: "LOGOUT" });

    // If user is logged in via SSO, redirect to login prompt page
    if (ReadFromSession(StorageKey.Is_Sso) === "true") {
      RemoveFromSession(StorageKey.Is_Sso);
      navigate(urls.sso.logout, { state: { rest_id } });
    } else {
      navigate(urls.home);
    }
  };
  const handleHelpCallback = (value) => {
    setOpenHelpDialog(false);
    if (value) {
      setSnackbar({
        message: Context.langData.help_support_successfully,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.help_support_failed,
        severity: "error",
      });
    }
  };

  const expireToken = () => {
    instance.post("token_timeout", { refresh_token: refreshToken }).then(() => {
      handleCloseTestingDialog();
    });
  };

  const expireSession = () => {
    instance
      .post("session_timeout", { refresh_token: refreshToken })
      .then(() => {
        handleCloseTestingDialog();
      });
  };

  const handleCloseTestingDialog = () => {
    setOpenTestDialog(false);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const TestingSessionDialog = ({ open, onClose }) => (
    // This is an hidden debugging dialog that can be activated with handleDoubleClickOpenTestingDialog
    // Do not make accessible in production versions!
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Choose Actions</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          This is just for QA testing, choose proper action to expire token or
          expire the whole session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.btn} onClick={expireToken}>
          Expire Token
        </Button>
        <Button className={classes.btn} onClick={expireSession}>
          Expire Session
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Paper>
        <Toolbar className={classes.toolbar}>
          <TestingSessionDialog
            onClose={handleCloseTestingDialog}
            open={openTestDialog}
          />
          {!isTablet && (
            <Box className={classes.flexRow}>
              <MonerisLogo width={210} height={13} />

              {showUser && (
                <Box>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    className={classes.btn}
                    onClick={handleClick}>
                    <span style={{ marginRight: 5, color: "#fff" }}>
                      {selectedRestaurant?.name}
                    </span>
                    <UserIcon color='#fff' />
                  </Button>
                  <StyledMenu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menu}>
                    {email?.restaurants?.length > 1 && (
                      <StyledMenuItem
                        onClick={() => navigate("/branches")}
                        style={{
                          paddingRight: 8,
                          borderBottom: "1px solid #EEEEEE",
                        }}>
                        <ListItemIcon
                          style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                          <ReturnIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={Context.langData.change_location}
                          style={{ marginLeft: 12 }}
                        />
                      </StyledMenuItem>
                    )}
                    <StyledMenuItem
                      onClick={() => handleOpenHelp(true)}
                      style={{
                        paddingRight: 8,
                        borderBottom: "1px solid #EEEEEE",
                      }}>
                      <ListItemIcon
                        style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                        <HelpIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={Context.langData.help_support}
                        style={{ marginLeft: 12 }}
                      />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={() => handleClose("logout")}
                      style={{ paddingRight: 8 }}>
                      <ListItemIcon
                        style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <div>
                        <ListItemText
                          primary={email?.email}
                          style={{
                            marginLeft: 10,
                            color: "#707272",
                            marginBottom: 0,
                          }}
                        />
                        <ListItemText
                          primary={Context.langData.logout}
                          style={{ marginLeft: 12, marginTop: 0 }}
                        />
                      </div>
                    </StyledMenuItem>
                  </StyledMenu>
                </Box>
              )}
            </Box>
          )}
          {isTablet && (
            <Box className={classes.flexRow}>
              <Box className={classes.flexRow} style={{ width: "20%" }}>
                {!mobileNavOpen && (
                  <IconButton
                    color='inherit'
                    onClick={() => {
                      onMobileNavOpen(true);
                    }}>
                    <MenuIcon style={{ color: "white" }} />
                  </IconButton>
                )}
                {mobileNavOpen && (
                  <IconButton
                    color='inherit'
                    onClick={() => {
                      onMobileNavOpen(false);
                    }}>
                    <CloseCross color='#FFFFFF' width='16' height='16' />
                  </IconButton>
                )}
                <MonerisLogo width={210} height={13} />
              </Box>

              <Box>
                <Button
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  className={classes.btn}
                  onClick={handleClick}>
                  <H4 label={email?.name} />
                  <UserIcon />
                </Button>
                <StyledMenu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.menu}>
                  <StyledMenuItem
                    onClick={() => navigate("/branches")}
                    style={{
                      paddingRight: 8,
                    }}>
                    <ListItemIcon
                      style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                      <ReturnIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={Context.langData.change_location}
                      style={{ marginLeft: 12 }}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleOpenHelp(true)}
                    style={{ paddingRight: 8 }}>
                    <ListItemIcon
                      style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={Context.langData.help_support}
                      style={{ marginLeft: 12 }}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleClose("logout")}
                    style={{ paddingRight: 8 }}>
                    <ListItemIcon
                      style={{ maxWidth: 20, minWidth: 20, marginLeft: 8 }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={Context.langData.logout}
                      style={{ marginLeft: 12 }}
                    />
                  </StyledMenuItem>
                </StyledMenu>
              </Box>
            </Box>
          )}
        </Toolbar>
      </Paper>
      <MyAccountDialog open={openDialog} handleClose={handlecloseMyAccount} />
      {openHelpDialog && (
        <HelpAndSupport
          data-qaid='commentList_editComment'
          open={openHelpDialog}
          handleHelpCallback={handleHelpCallback}
          onClose={() => setOpenHelpDialog(false)}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </AppBar>
  );
};
const HelpAndSupport = ({ open, onClose, handleHelpCallback }) => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const productList = [
    { name: Context.langData.product_1, value: 0 },
    { name: Context.langData.product_2, value: 1 },
    { name: Context.langData.product_3, value: 2 },
    { name: Context.langData.product_4, value: 3 },
    { name: Context.langData.product_5, value: 4 },
  ];
  const reachOut_List = [
    { name: Context.langData.phone, value: 0 },
    { name: Context.langData.email_header, value: 1 },
  ];
  const callback = (response) => {
    handleHelpCallback(response);
  };
  return (
    <RightDrawer data-qaid='createcomment_centermodal' open={open}>
      <Formik
        data-qaid='activation_formik'
        initialValues={{
          email: "",
          phoneNumber: "",
          product_name: "",
          reachOut_type: "",
          comment: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(Context.langData.email_valid_error)
            .max(255)
            .required(Context.langData.email_valid_error_help),
          phoneNumber: Yup.string()
            .max(255)
            .required(Context.langData.phone_req_error),
          product_name: Yup.string()
            .max(255)
            .required(Context.langData.product_type_req_error),
          reachOut_type: Yup.string()
            .max(255)
            .required(Context.langData.reach_out_error),
          comment: Yup.string()
            .max(255)
            .required(Context.langData.comment_req_error),
        })}
        onSubmit={(values) => {
          dispatch(
            saveHelpSupport(
              {
                email: values.email,
                phone: values.phoneNumber.replace(/-/g, ""),
                application: values.product_name,
                preferred_contact: values.reachOut_type,
                comment: values.comment,
              },
              callback
            )
          );
        }}>
        {({
          errors,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              style={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
                height: "100%",
              }}>
              <Grid
                style={{
                  display: "flex",
                  // eslint-disable-next-line no-undef
                  height: window.innerHeight - 50,
                  overflowX: "auto",
                  flexDirection: "column",
                  paddingBottom: 50,
                }}>
                <Grid
                  data-qaid='createcategory_close_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}>
                  <CircleClose
                    data-qaid='createcategory_closeicon'
                    size={20}
                    onClick={onClose}
                    style={{ marginRight: 36, marginTop: 20 }}
                  />
                </Grid>
                <H6
                  data-qaid='createcomment_header'
                  style={{ marginLeft: 36 }}
                  label={Context.langData.help_and_support}
                />
                {/* </Grid> */}
                <Grid
                  data-qaid='createcomment_comment_grid'
                  container
                  direction='column'
                  style={{ display: "flex", width: 590, margin: 32 }}>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}>
                    <AvenirBlackH4
                      data-qaid='createcomment_valuelabel'
                      label={Context.langData.login_email}
                    />
                    <InputText
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={(val) =>
                        setFieldValue("email", val.target.value)
                      }
                      fullWidth
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                      id='email'
                      name='email'
                      variant='outlined'
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}>
                    <AvenirBlackH4
                      data-qaid='createcomment_valuelabel'
                      label={Context.langData.phone}
                    />
                    <InputMaskComponent
                      value={values.phoneNumber}
                      regex='phone'
                      isFormik
                      error={touched.phoneNumber && errors.phoneNumber}
                      onBlur={(value) => {
                        setFieldError("phoneNumber", value);
                      }}
                      handleChange={(val) => setFieldValue("phoneNumber", val)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      id='phoneNumber'
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}>
                    <AvenirBlackH4
                      data-qaid='createcomment_valuelabel'
                      label={Context.langData.product_help}
                    />
                    <SingleSelect
                      data-qaid='alltrancation_ordertype_singleselect'
                      list={productList}
                      value={values.product_name}
                      handleChange={(e) => {
                        setFieldValue("product_name", e.target.value);
                      }}
                      valueKey='name'
                      displayKey='name'
                      // placeholder='Select'
                      borderStyle={
                        touched.product_name && errors.product_name
                          ? "1px solid #AB1212"
                          : "1px solid #707272"
                      }
                      border={false}
                      height={56}
                    />
                    {touched.product_name && errors.product_name && (
                      <FormHelperText
                        data-qaid='textinputmask_formhelpertext'
                        style={{
                          color: "#AB1212",
                          display: "flex",
                          margin: "3px 0px 0px 0px",
                        }}>
                        <ExclamationIcon
                          color='#AB1212'
                          style={{ marginRight: 10 }}
                        />
                        <H5
                          label={errors.product_name}
                          style={{ color: "#AB1212" }}
                        />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}>
                    <AvenirBlackH4
                      data-qaid='createcomment_valuelabel'
                      label={Context.langData.how_reach}
                    />
                    <SingleSelect
                      data-qaid='alltrancation_ordertype_singleselect'
                      list={reachOut_List}
                      value={values.reachOut_type}
                      handleChange={(e) => {
                        setFieldValue("reachOut_type", e.target.value);
                      }}
                      valueKey='name'
                      displayKey='name'
                      borderStyle={
                        touched.reachOut_type && errors.reachOut_type
                          ? "1px solid #AB1212"
                          : "1px solid #707272"
                      }
                      placeholder='Select'
                      border={false}
                      height={56}
                    />
                    {touched.reachOut_type && errors.reachOut_type && (
                      <FormHelperText
                        data-qaid='textinputmask_formhelpertext'
                        style={{
                          color: "#AB1212",
                          display: "flex",
                          margin: "3px 0px 0px 0px",
                        }}>
                        <ExclamationIcon
                          color='#AB1212'
                          style={{ marginRight: 10 }}
                        />
                        <H5
                          label={errors.reachOut_type}
                          style={{ color: "#AB1212" }}
                        />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 24,
                    }}>
                    <AvenirBlackH4
                      data-qaid='createcomment_valuelabel'
                      label={Context.langData.comment}
                    />
                    <TextField
                      value={values.comment}
                      onChange={(val) =>
                        setFieldValue("comment", val.target.value)
                      }
                      id='notes'
                      multiline
                      rows={5}
                      InputProps={{ disableUnderline: true }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{ maxLength: 500 }}
                      style={{
                        width: "100%",
                        height: 128,
                        backgroundColor: "#FFF",
                        outline: "none",
                        fontSize: 14,
                        borderRadius: 4,
                        fontFamily: "AvenirLT-Medium",
                        color: "#051D33",
                        padding: 10,
                        border:
                          touched.comment && errors.comment
                            ? "1px solid #AB1212"
                            : "1px solid #707272",
                      }}
                    />
                    {touched.comment && errors.comment && (
                      <FormHelperText
                        data-qaid='textinputmask_formhelpertext'
                        style={{
                          color: "#AB1212",
                          display: "flex",
                          margin: "3px 0px 0px 0px",
                        }}>
                        <ExclamationIcon
                          color='#AB1212'
                          style={{ marginRight: 10 }}
                        />
                        <H5
                          label={errors.comment}
                          style={{ color: "#AB1212" }}
                        />
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                data-qaid='createcomment_action_grid'
                container
                style={{
                  position: "absolute",
                  bottom: 0,
                  backgroundColor: "#FAFAFA",
                  height: 100,
                  alignItems: "center",
                  display: "flex",
                  width: "100%",
                  borderTop: "1px solid #C3C3C3",
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingRight: 36,
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    data-qaid='loginView_formik_login_button'
                    style={{
                      backgroundColor: "#FFB600",
                      height: 40,
                      width: 105,
                    }}
                    type='submit'
                    variant='contained'>
                    <AvenirBlackH4
                      data-qaid='loginView_formik_login_label'
                      label={Context.langData.submit}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </RightDrawer>
  );
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#C8E6F1",
      borderRadius: 5,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#051D33",
      },
    },
  },
}))(MenuItem);

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
