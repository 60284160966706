import React, { useContext } from "react";
import { Paper, makeStyles, Grid, Button, Typography } from "@material-ui/core";
import { AvenirBlackH7, AvenirBlackH3, AvenirBlackH4 } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { EmptyState, CirclePluse } from "./SVG";

const useStyles = makeStyles({
  paper: {
    background: "#FAFAFA",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "600px",
  },
  button: {
    height: 40,
    borderRadius: 5,
    backgroundColor: "#FFB600",
    width: "100%",
    marginTop: 17,
  },
  text: {
    color: "#051D33",
    fontSize: 16,
    fontFamily: "AvenirLT-Medium",
    textAlign: "center",
    lineHeight: "26px",
  },
  underLineText: {
    color: "#007191",
    fontSize: 16,
    fontFamily: "AvenirLT-Medium",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    cursor: "pointer",
    lineHeight: "26px",
  },
});
const EmptyLandingPage = ({
  type,
  title,
  bodyTextPrimary,
  bodyTextSecondary,
  buttonText,
  boldText,
  routeText,
  onRouteClick = () => {},
  onButtonClick = () => {},
}) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const renderer = () => {
    switch (type) {
      case "Button":
        return (
          <>
            <AvenirBlackH7
              label={title}
              style={{ marginTop: 17, textAlign: "center", fontWeight: 900 }}
            />
            <Grid style={{ marginTop: 17, textAlign: "center" }}>
              <AvenirBlackH3 label={bodyTextPrimary} />
              <AvenirBlackH3 label={Context.langData.click_below_get_started} />
            </Grid>
            <Button
              startIcon={<CirclePluse />}
              onClick={onButtonClick}
              variant='contained'
              color='default'
              style={{
                height: 40,
                borderRadius: 5,
                backgroundColor: "#FFB600",
                width: "auto",
                marginTop: 17,
                textAlign: "center",
              }}>
              <AvenirBlackH4 label={buttonText} />
            </Button>
          </>
        );
      case "Route":
        return (
          <Grid>
            <AvenirBlackH7
              label={title}
              style={{ marginTop: 17, textAlign: "center", fontWeight: 900 }}
            />
            <Grid style={{ marginTop: 17, textAlign: "center" }}>
              <AvenirBlackH3
                label={bodyTextPrimary}
                style={{ marginBottom: 17 }}
              />
            </Grid>
            <Typography
              component='span'
              className={classes.text}
              style={{ fontWeight: 800 }}>
              {`${boldText} `}
              <Typography component='span' className={classes.text}>
                {bodyTextSecondary}
              </Typography>
            </Typography>
            <Typography className={classes.text}>
              {`${Context.langData.go_to} `}
              <Typography
                component='span'
                className={classes.underLineText}
                onClick={{ onRouteClick }}>
                {routeText}
              </Typography>
            </Typography>
          </Grid>
        );

      default:
        return () => {};
    }
  };
  return (
    <Paper data-qaid='empty_landing_page' className={classes.paper}>
      <EmptyState />
      {renderer()}
    </Paper>
  );
};

export default EmptyLandingPage;
