import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxes } from "../../../../redux/actions";
import TaxesList from "./taxesList";

const Taxes = () => {
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTaxes(restId.rest_id));
  }, []);
  return (
    <div>
      <TaxesList data-qaid='taxes-taxeslist' />
    </div>
  );
};

export default Taxes;
