import React, { useContext } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { AvenirBlackH4, H8 } from "../utils/text";
import MyContext from "../Provider/MyContext";
import styles from "../styles";
import { CircleClose, Delete as DeleteIcon, ArrowLeft } from "./SVG";
import { RightDrawer, Dialog as AlertDialog } from ".";

const RightDrawerTitleButtons = ({
  open,
  onClose = () => {},
  isEditing,
  children,
  alertOpenClose,
  title,
  handleCreate = () => {},
  handleEdit = () => {},
  handleDelete = () => {},
  handleAlertCloseOpen = () => {},
  deleteTitle,
  deleteMessage,
  saveButton,
  backButton,
}) => {
  const Context = useContext(MyContext);
  const style = styles();
  return (
    <RightDrawer open={open} padding={1} onClose={onClose}>
      <Grid
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          style={{
            display: "flex",
            height: "80%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <IconButton
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}>
              <CircleClose size={20} />
            </IconButton>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}
            onClick={onClose}>
            <ArrowLeft
              data-qaid='categories_editBack_icon'
              onClick={() => onClose()}
              style={{ cursor: "pointer" }}
            />
            <AvenirBlackH4
              style={{ color: "#007191", cursor: "pointer" }}
              label={backButton || Context.langData.back}
            />
          </Grid>
          <H8
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={title}
          />

          {children}
        </Grid>
        {!isEditing && (
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid container style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='createaddon_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    height: 40,
                    width: 103,
                    border: "2px solid #051D33",
                  }}>
                  <AvenirBlackH4 label={Context.langData.cancel} />
                </Button>
                <Button
                  className={style.buttonClass}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={handleCreate}>
                  <AvenirBlackH4 label={Context.langData.save} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isEditing && (
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid container flex style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 32,
                  marginBottom: 32,
                  width: "100%",
                }}>
                <Button
                  onClick={() => handleAlertCloseOpen(true)}
                  variant='text'
                  style={{ marginLeft: 45 }}>
                  <DeleteIcon width={42} height={42} color='#8b0000' />
                  <AvenirBlackH4
                    label={Context.langData.delete}
                    style={{ marginLeft: 10, color: "#8b0000" }}
                  />
                </Button>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    className={style.buttonClass}
                    onClick={onClose}
                    variant='contained'
                    style={{
                      backgroundColor: "#FFF",
                      marginRight: 16,
                      height: 40,
                      width: 103,
                      border: "2px solid #051D33",
                    }}>
                    <AvenirBlackH4 label={Context.langData.cancel} />
                  </Button>
                  <Button
                    className={style.buttonClass}
                    variant='contained'
                    style={{
                      backgroundColor: "#FFB600",
                      marginRight: 32,
                      height: 40,
                      width: "auto",
                    }}
                    onClick={handleEdit}>
                    <AvenirBlackH4
                      label={saveButton || Context.langData.save}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {alertOpenClose && (
        <AlertDialog
          showDialog={alertOpenClose}
          body={deleteMessage}
          heading={deleteTitle}
          IsError={false}
          showExtra={false}
          handleClose={handleDelete}
        />
      )}
    </RightDrawer>
  );
};

export default RightDrawerTitleButtons;
