import * as React from "react";

const ArrowLeft = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.33634 6.63003C6.54067 6.81579 6.55572 7.13201 6.36997 7.33634L2.13299 11.997H23.75C24.0261 11.997 24.25 12.2209 24.25 12.497C24.25 12.7732 24.0261 12.997 23.75 12.997H2.12756L6.36997 17.6637C6.55572 17.868 6.54067 18.1842 6.33634 18.37C6.13201 18.5557 5.81578 18.5407 5.63003 18.3363L0.63003 12.8363C0.456657 12.6456 0.456657 12.3544 0.63003 12.1637L5.63003 6.66367C5.81578 6.45934 6.13201 6.44428 6.33634 6.63003Z'
      fill='#007191'
    />
  </svg>
);

const BackIcon = React.memo(ArrowLeft);
export default BackIcon;
