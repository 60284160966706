import React, { useContext } from "react";
import { Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { AvenirBlackH4, H4, H5Grey } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { InfoOutlined } from "./SVG";
import { CustomCheckbox, ModifierCounter } from ".";

const useStyles = makeStyles({
  customToolTip: {
    backgroundColor: "#051D33",
    color: "#FFF",
    fontSize: "12px",
    fontWeight: 300,
    fontFamily: "AvenirLT",
  },
});

const ModifierSetQuantityLimit = ({
  checked,
  checkBoxLabel,
  initialCountOfMax,
  minOfMax,
  maxOfMax,
  onCheckboxChange,
  onMaxChange,
  multiSelectMax,
  ModifierSetQuantityLimitDisable = true,
  showCommentInTooltip = false,
}) => {
  const classes = useStyles();

  const { langData } = useContext(MyContext);
  // const [quantityLimChecked, setquantityLimChecked] = useState([]);

  const handleMaxChange = (value) => {
    onMaxChange(value);
  };

  const handleCheckboxChange = (e) => {
    onCheckboxChange(e.target.checked);
    // setquantityLimChecked(e.target.checked);
  };

  return (
    <Grid
      container
      direction='column'
      style={{
        width: "350px",
        foregroundColor: ModifierSetQuantityLimitDisable
          ? "#D3D3D3"
          : "initial", // Grey out when disabled
        pointerEvents: ModifierSetQuantityLimitDisable ? "none" : "auto", // Disable click events when disabled
        opacity: ModifierSetQuantityLimitDisable ? 0.5 : 1, // Reduce opacity when disabled
      }}>
      <Grid item container alignItems='center'>
        <CustomCheckbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={ModifierSetQuantityLimitDisable} // Disable checkbox when disabled
        />
        <AvenirBlackH4 label={checkBoxLabel} />
        {showCommentInTooltip ? (
          <Tooltip
            title={langData.set_quantuty_limit_comment}
            classes={{ tooltip: classes.customToolTip }}>
            <IconButton>
              <InfoOutlined style={{ height: 15, width: 15 }} />
            </IconButton>
          </Tooltip>
        ) : (
          <H5Grey
            style={{
              marginLeft: 30,
              color: ModifierSetQuantityLimitDisable ? "#D3D3D3" : "#A9A9A9",
            }}
            label={langData.set_quantuty_limit_comment}
          />
        )}{" "}
      </Grid>
      <Grid item container direction='column'>
        <Grid
          style={{
            width: "205px",
            marginTop: 10,
            marginLeft: 4,
            marginRight: 4,
            border: "1px",
            borderColor: ModifierSetQuantityLimitDisable
              ? "#d3d3d3"
              : "initial", // Grey out border when disabled
          }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 1,
            }}>
            <H4
              label={langData.limit_per_modifier}
              style={{
                colors: checked ? "initial" : "#D3D3D3",
              }}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 1,
              width: "95%",
            }}>
            <ModifierCounter
              initialCount={initialCountOfMax}
              onChange={handleMaxChange}
              max={maxOfMax}
              min={minOfMax}
              multiSelectMax={multiSelectMax}
              ModifierCounterDisable={!checked}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModifierSetQuantityLimit;
