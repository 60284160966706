/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  IconButton,
  Grid,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { AvenirBlackH3, DotText } from "../utils/text";
import { ConvertPrice } from "../utils/util";
import { RightArrow } from "./SVG";
import DownArrow from "./SVG/DownArrow";
import { CustomPagination, CustomCheckbox } from ".";

const useStyles = makeStyles(() => ({
  root: {
    width: "calc(100% - 40px)",
    margin: "0 20px",
    overflowX: "auto",
    position: "relative",
  },
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0 8px",
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    "& th": {
      fontWeight: "bold",
      border: "none",
      padding: "12px",
    },
  },
  dataRow: {
    cursor: "pointer",
    backgroundColor: "#FAFAFA",
    "&:hover": {
      backgroundColor: "#C8E6F1",
    },
    "& td": {
      border: "none",
      padding: "12px",
    },
    "& td:first-child": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& td:last-child": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
  cell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
  },
  tooltipCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
  },
  tooltipCellLeftBorderGreen: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "green !important",
    borderLeftWidth: "5px !important",
  },
  tooltipCellLeftBorderRed: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "red !important",
    borderLeftWidth: "5px !important",
  },
  checkboxCell: {
    width: 40,
  },
  actionCell: {
    width: 40,
  },
  sortableHeader: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  paginationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  rightArrowContainer: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    background: "#fff",
  },
  beforeRotation: {},
  rotatedArrow: {
    transform: "rotate(180deg)",
  },
}));

const NewDataTable = ({
  columns,
  rows,
  total,
  onPageChange,
  onClickAction,
  onCheckedIds,
  refresh,
  sortingOnClick,
  offset,
}) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    field: "",
    order: "asc",
  });
  const tableContainerRef = useRef(null);

  useEffect(() => {
    if (onCheckedIds) onCheckedIds(selectedId);
  }, [selectedId]);

  useEffect(() => {
    if (refresh) {
      setSelectedId([]);
      setCheckedAll(false);
    }
  }, [refresh]);

  useEffect(() => {
    setSelectedId([]);
    setCheckedAll(false);
  }, [rows]);

  useEffect(() => {
    const handleResize = () => {
      if (
        tableContainerRef.current &&
        tableContainerRef.current.scrollWidth >
          tableContainerRef.current.clientWidth
      ) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [rows, columns]);

  useEffect(() => {
    if (sortingOnClick) {
      sortingOnClick(sortConfig);
    }
  }, [sortConfig]);

  const toggleSelection = (id) => {
    const updatedSelectedId = selectedId.includes(id)
      ? selectedId.filter((itemId) => itemId !== id)
      : [...selectedId, id];

    setSelectedId(updatedSelectedId);
    setCheckedAll(rows?.length === updatedSelectedId?.length);
  };

  const toggleSelectAll = (value) => {
    setCheckedAll(value);
    setSelectedId(
      value
        ? rows.map((item) => !item.isDisabled && item.id).filter(Boolean)
        : []
    );
  };

  const handleSortClick = (field) => {
    let newOrder;

    if (sortConfig.field === field) {
      newOrder = sortConfig.order === "asc" ? "desc" : "asc";
    } else {
      newOrder = "desc";
    }

    setSortConfig({ field, order: newOrder });
  };
  const formatValue = (isNumber, value) =>
    isNumber
      ? `$${value ? ConvertPrice(String(value)) : "0"}`
      : String(value === null ? "-" : value);

  const handleRightArrowClick = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className={classes.root} ref={tableContainerRef}>
      <TableContainer>
        <Table className={classes.table} aria-label='data table'>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.checkboxCell}>
                <CustomCheckbox
                  checked={checkedAll}
                  onChange={() => toggleSelectAll(!checkedAll)}
                />
              </TableCell>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  className={classes.cell}
                  onClick={() =>
                    column.showSort !== false && handleSortClick(column.field)
                  }>
                  <div className={classes.sortableHeader}>
                    <AvenirBlackH3 label={column.headerName} />
                    {column.showSort !== false && (
                      <Grid
                        className={
                          sortConfig.field === column.field &&
                          sortConfig.order === "desc"
                            ? classes.rotatedArrow
                            : classes.beforeRotation
                        }>
                        <IconButton>
                          <DownArrow />
                        </IconButton>
                      </Grid>
                    )}
                  </div>
                </TableCell>
              ))}
              <TableCell className={classes.actionCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row, rowIndex) => (
                <TableRow key={rowIndex} className={classes.dataRow}>
                  <TableCell className={classes.checkboxCell}>
                    <CustomCheckbox
                      checked={selectedId.includes(row.id)}
                      onChange={() => toggleSelection(row.id)}
                      isDisabled={row.isDisabled}
                      id={row.id}
                    />
                  </TableCell>
                  {columns.map((column, colIndex) => {
                    const cellValue = formatValue(
                      column.isNumber,
                      row[column.field]
                    );
                    const isOverflowing = cellValue.length > 15;
                    const tooltipClass =
                      column.statusFlag &&
                      (cellValue === "Active"
                        ? classes.tooltipCellLeftBorderGreen
                        : classes.tooltipCellLeftBorderRed);
                    return isOverflowing ? (
                      <Tooltip
                        key={colIndex}
                        title={cellValue}
                        placement='top'
                        arrow>
                        <TableCell
                          onClick={(e) => onClickAction(row, e)}
                          className={tooltipClass || classes.tooltipCell}>
                          <DotText label={cellValue || "-"} />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell
                        onClick={(e) => onClickAction(row, e)}
                        key={colIndex}
                        className={tooltipClass || classes.tooltipCell}>
                        <DotText
                          style={{ color: "inherit" }}
                          label={cellValue || "-"}
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell
                    className={classes.actionCell}
                    onClick={(e) => onClickAction(row, e)}>
                    <RightArrow />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 2}
                  align='center'
                  className={classes.cell}>
                  <AvenirBlackH3 label='No records found' />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationContainer}>
        <CustomPagination
          total={total}
          onChange={onPageChange}
          offset={offset}
        />
      </div>
      {showRightArrow && (
        <div className={classes.rightArrowContainer}>
          <IconButton
            color='primary'
            onClick={handleRightArrowClick}
            aria-label='scroll right'>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default NewDataTable;
