import React, { useState, useContext, useMemo } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AvenirBlackH4, H6 } from "../../../../utils/text";
import CircleClose from "../../../../components/SVG/CircleClose";
import RightDrawer from "../../../../components/RightDrawer";
import BackIcon from "../../../../components/SVG/Back";
import AutoComplete from "../../../../components/AutoCompliteModifiers";
import SnackBar from "../../../../components/SnackBar";
import MyContext from "../../../../Provider/MyContext";
import AddonItem from "./AddonItem";

const Modifier = ({ onClose, show, onSave, item, addedModifiers }) => {
  const allAddons = useSelector((state) => state.menu.addons);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [addonItems, setAddonItems] = useState([]);
  const Context = useContext(MyContext);

  const addons = useMemo(() => {
    let addonsArray = [];
    allAddons.map((addon) => {
      const a = addedModifiers.findIndex(
        (e) => e.addon_cat_id === addon.addon_cat_id
      );
      if (a === -1) {
        addonsArray.push(addon);
      }
    });
    addonsArray = addonsArray.map((addon, index) => ({
      ...addon,
      position: index,
    }));
    return addonsArray;
  }, [allAddons, addedModifiers]);

  const onSaveModifier = () => {
    const position = addedModifiers.length;
    onSave({
      ...selectedAddon,
      addons: addonItems,
      position,
    });
  };

  const onAddonChange = (e) => {
    setSelectedAddon(e);
    if (e) {
      setAddonItems(e.addons);
    } else {
      setAddonItems([]);
    }
  };
  const onAddonItemChange = (item, index) => {
    const temp = [...addonItems];
    temp[index] = item;
    setAddonItems(temp);
  };
  const onAddonItemDelete = (index) => {
    if (addonItems.length > 1) {
      const temp = [
        ...addonItems.slice(0, index),
        ...addonItems.slice(index + 1),
      ];
      setAddonItems(temp);
    }
  };

  return (
    <RightDrawer open={show} padding={1}>
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <Grid
        style={{
          position: "relative",
          display: "flex",
          height: "100%",
          width: 592,
          padding: "10px 24px",
          flexDirection: "column",
        }}>
        <Grid
          container
          style={{
            overflow: "auto",
            // eslint-disable-next-line no-undef
            height: window.innerHeight - 70,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}>
          <Grid style={{ width: "100%" }}>
            <Grid
              container
              data-qaid='assignkitchen_close_grid'
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <CircleClose
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginTop: 20 }}
              />
            </Grid>
            <Grid
              container
              data-qaid='assignkitchen_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",

                marginBottom: 10,
              }}>
              <BackIcon
                data-qaid='categories_editBack_icon'
                onClick={onClose}
                style={{ cursor: "pointer" }}
              />
            </Grid>

            <Grid container>
              <H6
                label={Context.langData.add_modifier_groups}
                style={{ marginTop: 20 }}
              />
            </Grid>

            {!item && (
              <Grid container direction='column'>
                <AutoComplete
                  options={addons}
                  style={{ width: "100%" }}
                  labelKey='addon_cat_name'
                  onChange={(e) => {
                    onAddonChange(e);
                  }}
                />
              </Grid>
            )}

            {selectedAddon && (
              <Box display={selectedAddon ? "contents" : "none"}>
                <Grid container>
                  <Grid container>
                    <Grid container style={{ marginTop: 20 }}>
                      {addonItems.map((addon, index) => (
                        <Grid container key={index}>
                          <AddonItem
                            item={addon}
                            onChange={(item) => onAddonItemChange(item, index)}
                            onDelete={() => onAddonItemDelete(index)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <Button
            variant='contained'
            style={{
              border: "2px solid #051D33",
              marginRight: 20,
              height: 40,
              width: 103,
              backgroundColor: "#FFF",
            }}
            onClick={onClose}>
            <AvenirBlackH4 label={Context.langData.cancel} />
          </Button>
          <Button
            disabled={selectedAddon === null}
            variant='contained'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              width: 90,
            }}
            onClick={onSaveModifier}>
            <AvenirBlackH4 label={Context.langData.save} />
          </Button>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default Modifier;
