import React, { useContext, useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { H6, AvenirBlackH4 } from "../../utils/text";
import MyContext from "../../Provider/MyContext";
import {
  fetchPettyCashCategories,
  fetchPettyCashSession,
} from "../../redux/actions";
import { FeatureKeys } from "../../enums";

const PettyCash = () => {
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pettyCashList = [
    {
      key: "expenses",
      title: Context.langData.expenses,
      id: 0,
      route: "pettyCash/expenses",
    },
    {
      key: "categories",
      title: Context.langData.categories,
      id: 1,
      route: "pettyCash/categories",
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const featureFlagClient = useLDClient();
  let pettyCashFlagValue = false;
  if (featureFlagClient && selectedRestaurant?.rest_id) {
    const featureFlagContext = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(featureFlagContext, null, () => {});

    pettyCashFlagValue = featureFlagClient.variation(
      FeatureKeys.PettyCash,
      true
    );
  }
  const isPettyCashPermission = useMemo(() => {
    const isIndex = userPermissions.findIndex((e) => e.key === "petty_cash");
    if (isIndex !== -1) {
      if (userPermissions[isIndex].status) {
        return true;
      }
      return false;
    }
    return false;
  }, [userPermissions]);

  useEffect(() => {
    dispatch(fetchPettyCashCategories());
    dispatch(fetchPettyCashSession());
    const item = pettyCashList.find((item) =>
      location.pathname.includes(item.route)
    );
    if (item) {
      setSelectedIndex(item.id);
    } else if (location.pathname.endsWith("pettyCash")) {
      handleSelectItem(pettyCashList[0]);
    }
  });
  const handleSelectItem = (item) => {
    setSelectedIndex(item.id);
    navigate(`/home/${item.route}`);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isPettyCashPermission && pettyCashFlagValue ? (
        <Grid
          data-qaid='pettycash_index_grid'
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "100%",
            width: "100%",
          }}>
          <Grid
            data-qaid='pettycash_index_contentgrid'
            style={{ height: "20%" }}>
            <Grid
              data-qaid='pettycash_index_titlegrid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginLeft: 36 }}>
              <H6
                data-qaid='pettycash_index_title_label'
                label={Context.langData.pettycash}
                style={{ color: "#051D33", paddingTop: 36 }}
              />
            </Grid>
            <Grid
              data-qaid='pettycash_index_bodygrid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                marginLeft: 36,
                flexDirextion: "row",
                marginBottom: 20,
                marginTop: 20,
              }}>
              {pettyCashList.map((item, i) => (
                <Button
                  data-qaid='pettycash_button'
                  style={{
                    backgroundColor:
                      selectedIndex === item.id ? "#051D33" : "#EEEEEE",
                    marginRight: 1,
                    borderRadius: 0,
                    borderTopLeftRadius: i === 0 ? 5 : 0,
                    borderBottomLeftRadius: i === 0 ? 5 : 0,
                    borderTopRightRadius:
                      pettyCashList.length - 1 === i ? 5 : 0,
                    borderBottomRightRadius:
                      pettyCashList.length - 1 === i ? 5 : 0,
                    height: 52,
                    width: 93,
                  }}
                  onClick={() => handleSelectItem(item)}>
                  <AvenirBlackH4
                    data-qaid='pettycash_button_label'
                    label={item.title}
                    style={{
                      color: selectedIndex === item.id ? "#FFFFFF" : "#051D33",
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Button>
              ))}
            </Grid>
            <Grid
              data-qaid='dashboard_index_border'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                borderBottom: "1px solid #EEEEEE",
                marginTop: 20,
              }}
            />
          </Grid>
          <Grid data-qaid='pettycash_body_view' style={{ height: "80%" }}>
            <Outlet />
          </Grid>
        </Grid>
      ) : (
        <Grid
          data-qaid='pettycash_index_body_grid'
          style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
          <Grid
            data-qaid='pettycash_index_main_grid'
            item
            xs={12}
            md={6}
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid
              data-qaid='pettycash_index_title_grid'
              style={{ marginLeft: 56, marginRight: 56, paddingTop: 50 }}>
              <H6
                data-qaid='pettycash_index_title'
                label={Context.langData.pettycash}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='pettycash_index_border'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              borderBottom: "1px solid #EEEEEE",
              marginTop: 12,
            }}
          />
          <Grid
            data-qaid='pettycash_nopermissions_View'
            item
            lg={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              paddingTop: "20%",
            }}>
            <H6
              data-qaid='pettycash_nopermissions_message'
              label={Context.langData.no_permission_error}
              style={{
                textAlign: "center",
                width: "55%",
                fontWeight: "bold",
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PettyCash;
