import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import {
  DataTable,
  SnackBar,
  Dialog as AlertDialog,
  MainBodyWrapper,
  TitleWrapper,
  ImportantReminder,
} from "../../../../components";
import styles from "../../../../styles/index";
import { deleteTax, fetchTaxes } from "../../../../redux/actions";
import { AvenirBlackH4 } from "../../../../utils/text";
import {
  PluseIcon as CirclePluse,
  Delete as DeleteIcon,
  DisableDelete,
} from "../../../../components/SVG";
import MyContext from "../../../../Provider/MyContext";
import EmptyLandingPage from "../../../../components/EmptyLandingPage";
import EditTaxes from "./editTaxes";
import CreateTaxes from "./createTaxes";

const TaxesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const TaxesData = useSelector((state) => state.taxConfigurationReducer.taxes);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [Taxes, setTaxes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);

  const bodyMessage =
    selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} taxes?`
      : Context.langData.delete_tax_body;

  useEffect(() => {
    const filterData = _.reverse(TaxesData);
    setTaxes(filterData);
  }, [TaxesData]);

  const [columns, setColumns] = useState([
    {
      field: "code",
      headerName: Context.langData.tax,
      size: "30%",
      sort: false,
    },
    {
      field: "desc",
      headerName: Context.langData.comment,
      size: "30%",
      sort: false,
    },
    {
      field: "value",
      headerName: Context.langData.value,
      size: "40%",
      sort: false,
    },
  ]);
  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  const commanCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchTaxes(restId.rest_id));
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };

  const handleClose = (val) => {
    if (val) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i of selectedId) {
        dispatch(deleteTax(restId.rest_id, JSON.stringify(i), deleteCallback));
      }
    }
    setShowDialog(false);
  };
  const deleteTaxes = (value) => {
    setShowDialog(value);
  };

  const deleteOrderType = () => {
    if (selectedId.length > 0) {
      deleteTaxes(true);
    }
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortComments = _.orderBy(
      Taxes,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(sortComments);
  };
  return (
    <MainBodyWrapper>
      <div data-qaid='taxeslist_div'>
        <TitleWrapper
          mainTitle={Context.langData.settings}
          subTitle={Context.langData.taxes}>
          <Grid
            data-qaid='taxeslist_grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            <Button
              className={style.buttonClass}
              data-qaid='otype_delete_button'
              style={{ color: "white", marginRight: 10 }}
              variant='text'
              id='cancel'
              onClick={deleteOrderType}
              disabled={selectedId?.length <= 0}>
              {selectedId?.length <= 0 ? (
                <DisableDelete
                  data-qaid='shifts_deleteicon'
                  width={16}
                  height={16}
                  style={{
                    marginRight: 5,
                  }}
                />
              ) : (
                <DeleteIcon
                  data-qaid='shifts_deleteicon'
                  width={16}
                  height={16}
                  style={{
                    marginRight: 5,
                  }}
                />
              )}

              <AvenirBlackH4
                data-qaid='otype_delete_label'
                label={Context.langData.delete}
                style={{
                  color: selectedId?.length <= 0 ? "#C3C3C3" : "#004C60",
                }}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='taxeslist_creategrid'
              style={{
                backgroundColor: "#FFB600",
                color: "white",
              }}
              variant='contained'
              id='cancel'
              onClick={() => toggleCreateDrawer(true)}
              startIcon={<CirclePluse />}>
              <AvenirBlackH4
                data-qaid='taxeslist_createlabel'
                label={Context.langData.add_tax_rate}
                style={{ paddingLeft: 5 }}
              />
            </Button>
          </Grid>
        </TitleWrapper>
        <ImportantReminder message={Context.langData.tax_important_reminder} />

        {Taxes.length > 0 ? (
          <DataTable
            data-qaid='taxeslist_datatable'
            refresh={openCreate}
            columns={columns}
            rows={Taxes}
            onCheckedIds={onCheckedIds}
            onClickAction={onClickAction}
            sortingOnClick={sortingOnClick}
          />
        ) : (
          <Grid style={{ margin: "10px 0px" }}>
            <EmptyLandingPage
              type='Button'
              title={Context.langData.no_taxes_added}
              bodyTextPrimary={Context.langData.all_local_sales_taxes}
              buttonText={Context.langData.add_tax_rate}
              onButtonClick={() => toggleCreateDrawer(true)}
            />
          </Grid>
        )}
        {openCreate && (
          <CreateTaxes
            data-qaid='taxeslist_createtaxes'
            open={openCreate}
            taxes={Taxes}
            onClose={() => toggleCreateDrawer(false)}
            callback={createCallback}
          />
        )}
        {openEdit && (
          <EditTaxes
            data-qaid='taxeslist_edittaxes'
            open={openEdit}
            row={row}
            taxes={Taxes}
            onClose={() => toggleEditDrawer(false)}
            callback={editCallback}
          />
        )}

        {snackbar && (
          <SnackBar
            data-qaid='taxeslist_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
        <AlertDialog
          data-qaid='taxeslist_alertdialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={Context.langData.delete_tax}
          IsError={false}
          handleClose={handleClose}
        />
      </div>
    </MainBodyWrapper>
  );
};

export default TaxesList;
