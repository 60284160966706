import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  fetchAllRecipeItems,
  fetchAllRecipeAddons,
  fetchAllStocks,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import { RoutedVerticalTabs } from "../../../components";

const InventoryRecipe = () => {
  const dispatch = useDispatch();
  const [, setSelectIndexTabChange] = useState(0);
  const Context = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAllRecipeItems());
    dispatch(fetchAllRecipeAddons());
    dispatch(fetchAllStocks());
  }, []);

  const Tabs = [
    { name: Context.langData.items, id: 0, route: "items" },
    { name: Context.langData.addons, id: 1, route: "addons" },
  ];

  const handleTabChange = (itemIdx) => {
    const item = Tabs[itemIdx];
    setSelectIndexTabChange(item.id);
    navigate(`/home/inventory/recipe/${item.route}`);
  };

  const selectedIndex = Tabs.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  return (
    <Grid data-qaid='recipe_index' container style={{ padding: 20 }}>
      <Grid
        data-qaid='recipe_verticalTab_grid'
        item
        xs={12}
        lg={2}
        style={{ backgroundColor: "#FAFAFA" }}>
        <RoutedVerticalTabs
          data-qaid='recipe_verticalTabs'
          tabData={Tabs}
          indexCallback={handleTabChange}
          selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
        />
      </Grid>
      <Grid
        data-qaid='recipe_content_grid'
        item
        xs={12}
        lg={10}
        style={{ paddingLeft: "10px" }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default InventoryRecipe;
