import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import DataTable from "../../../../components/DataTable";
import styles from "../../../../styles/index";
import {
  fetchPrinters,
  deletePrinters,
  fetchAssignKitchenPrinter,
  fetchKitchenPrinterUnassigned,
} from "../../../../redux/actions";
import AlertDialog from "../../../../components/Dialog";
import MyContext from "../../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../../utils/text";
import {
  Delete as DeleteIcon,
  CirclePluse,
  UploadIcon,
} from "../../../../components/SVG";
import {
  SnackBar,
  NoData,
  ButtonMenu,
  MainBodyWrapper,
  TitleWrapper,
} from "../../../../components";
import CreatePrinter from "./createPrinter";
import EditPrinter from "./editPrinter";
import CreateKitchenPrinterAssign from "./createKitchenPrinterAssign";

const PrintersList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: Context.langData.printer_name,
      size: "30%",
      sort: false,
    },
    {
      field: "ip_address",
      headerName: Context.langData.ip_address,
      size: "20%",
      sort: false,
    },
    {
      field: "assignment",
      headerName: Context.langData.station_assignment,
      size: "50%",
      sort: false,
    },
  ]);
  const ButtonMenuData = [
    {
      id: 1,
      icon: UploadIcon,
      name: Context.langData.assign_Printer,
    },
    {
      id: 2,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];
  const PrintersData = useSelector((state) => state.settingsReducer.printers);
  const Kitchens = useSelector((state) => state.metaReducer.printerUnassigned);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [openAssign, toggleAssignDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [Printers, setPrinters] = useState([]);
  const [data, setData] = useState([]);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState({});

  // eslint-disable-next-line no-nested-ternary
  const bodyMessage = err
    ? selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} printers?`
      : Context.langData.printer_body_delete
    : `Printer ${msg.printer_name} is currently assigned to Kitchen ${msg.kitchen_name}. If you delete this printer, it will be unassigned.`;

  useEffect(() => {
    const filterPrinterData = _.reverse(PrintersData);
    filterPrinterData.map((item) => {
      item.is_active = item.is_active ? "Active" : "Inactive";
    });

    setPrinters(filterPrinterData);
  }, [PrintersData]);

  const thisCallBack = (response) => {
    setData(response.data);
  };

  useEffect(() => {
    dispatch(fetchAssignKitchenPrinter({}, thisCallBack));
  }, []);

  const getSelectedPrinters = () => {
    // eslint-disable-next-line consistent-return
    const printerArray = PrintersData.filter((e) => {
      if (selectedId.includes(e.printer_id)) {
        e.selectableKitchens = [];
        e.assigned_kitchen_ids = [];
        if (e.assigned_kitchens) {
          // eslint-disable-next-line no-restricted-syntax
          for (const k of e.assigned_kitchens) {
            const obj = {
              id: k.id,
              name: k.name,
            };
            e.selectableKitchens.push(obj);
            e.assigned_kitchen_ids.push(k.id);
          }
        }
        if (Kitchens) {
          // eslint-disable-next-line no-restricted-syntax
          for (const k of Kitchens) {
            const obj = {
              id: k.kitchen_id,
              name: k.kitchen_name,
            };
            e.selectableKitchens.push(obj);
          }
        }
        return e;
      }
    });
    return printerArray;
  };

  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
    const data1 = data.find((e) => e.printer_id === ids);
    if (data1 === undefined) {
      setErr(true);
    } else {
      setErr(false);
      setMsg(data1);
    }
  };
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  const callback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleAssignDrawer(false);
    dispatch(fetchPrinters());
    dispatch(fetchKitchenPrinterUnassigned());
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.printer_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.printer_create_failed,
        severity: "error",
      });
    }
    callback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.printer_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.printer_edit_failed,
        severity: "error",
      });
    }
    callback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.printer_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.printer_delete_failed,
        severity: "error",
      });
    }
    callback();
  };
  const assignPrinterCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.printer_assign_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.printer_assign_failed,
        severity: "error",
      });
    }
    callback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deletePrinters(JSON.stringify(selectedId), deleteCallback));
    }
    setShowDialog(false);
  };

  function deletePrinter(value) {
    setShowDialog(value);
  }

  const callBack = (id) => {
    if (id === 1) {
      toggleAssignDrawer(true);
    } else if (id === 2) {
      if (selectedId.length > 0) {
        deletePrinter(true);
      }
    }
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortComments = _.orderBy(
      Printers,
      [value],
      [sortValue ? "asc" : "desc"]
    );
    setPrinters(sortComments);
  };

  return (
    <MainBodyWrapper>
      <div data-qaid='printer-list-div'>
        {Printers.length > 0 ? (
          <>
            <TitleWrapper
              mainTitle={Context.langData.settings}
              subTitle={Context.langData.printers}>
              <Grid
                data-qaid='printer-list-grid'
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                <ButtonMenu
                  style={{ marginLeft: 10 }}
                  menuItems={ButtonMenuData}
                  label={Context.langData.edit_printer}
                  callBack={callBack}
                  btnDisable={btnDisable}
                />
                <Button
                  className={style.buttonClass}
                  data-qaid='printerlist_create+button'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleCreateDrawer(true)}>
                  <CirclePluse data-qaid='printerlist_createicon' />
                  <AvenirBlackH4
                    data-qaid='printerlist_createlabel'
                    label={Context.langData.add_printer}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
              </Grid>
            </TitleWrapper>
            <DataTable
              data-qaid='printer-list-data-table'
              columns={columns}
              rows={Printers}
              onCheckedIds={onCheckedIds}
              onClickAction={onClickAction}
              isOnClick
              sortingOnClick={sortingOnClick}
            />
          </>
        ) : (
          <NoData page='printer' callback={() => toggleCreateDrawer(true)} />
        )}
        {openCreate && (
          <CreatePrinter
            data-qaid='printer-list-create-printer'
            open={openCreate}
            onClose={() => toggleCreateDrawer(false)}
            printers={Printers}
            callback={createCallback}
          />
        )}
        {openEdit && (
          <EditPrinter
            data-qaid='printer-list-edit-printer'
            open={openEdit}
            row={row}
            printers={Printers}
            onClose={() => toggleEditDrawer(false)}
            callback={editCallback}
            deleteCallback={deleteCallback}
          />
        )}
        {openAssign && (
          <CreateKitchenPrinterAssign
            data-qaid='printer-list-assign-printer'
            open={openAssign}
            // printers={PrintersData}
            printers={getSelectedPrinters()}
            kitchens={Kitchens}
            onClose={() => toggleAssignDrawer(false)}
            callback={assignPrinterCallback}
          />
        )}
        {snackbar && (
          <SnackBar
            data-qaid='categories-snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}

        <AlertDialog
          data-qaid='printer-list-alert-dialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={Context.langData.delete_printer}
          IsError={false}
          showExtra={err}
          handleClose={handleClose}
        />
      </div>
    </MainBodyWrapper>
  );
};

export default PrintersList;
