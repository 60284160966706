import React, { useContext, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";
import { RoutedVerticalTabs } from "../../../components";
import { fetchAllIndents } from "../../../redux/actions";

const InventoryStore = () => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const navigate = useNavigate();
  const location = useLocation();
  const Tabs = [
    {
      name: Context.langData.purchase_order,
      id: 0,
      route: "purchase-order",
    },
    {
      name: Context.langData.issues,
      id: 1,
      route: "issues",
    },
    {
      name: Context.langData.stock_balance,
      id: 2,
      key: "inventory_balance",
      route: "stock-balance",
    },
    {
      name: Context.langData.groups,
      id: 3,
      route: "groups",
    },
  ];

  // Permission mapping
  const isItemHasPermission = useMemo(
    () =>
      Tabs.map((tab) => {
        if (tab.key) {
          const permission = userPermissions.find(
            (perm) => perm.key === tab.key
          );
          return {
            ...tab,
            isPermission: permission?.status || false,
          };
        }
        return {
          ...tab,
          isPermission: true,
        };
      }),
    [userPermissions, Tabs]
  );

  // Determine the selected tab based on the current location
  const selectedIndex = Tabs.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  // Handle tab change
  const handleTabChange = (index) => {
    const selectedTab = isItemHasPermission[index];
    if (selectedTab) {
      navigate(`/home/inventory/store/${selectedTab.route}`);
    }
  };

  useEffect(() => {
    dispatch(fetchAllIndents());
    if (location.pathname.endsWith("inventory/store")) {
      handleTabChange(0);
    }
  }, []);

  return (
    <Grid
      data-qaid='stocks_verticalTabs'
      container
      style={{ backgroundColor: "white" }}>
      <Grid container style={{ margin: "20px" }}>
        <Grid item xs={12} lg={2} style={{ backgroundColor: "#FAFAFA" }}>
          <RoutedVerticalTabs
            tabData={Tabs}
            indexCallback={handleTabChange}
            selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
          />
        </Grid>
        <Grid
          data-qaid='viewsendStock_tabChange'
          item
          xs={12}
          lg={10}
          style={{ paddingLeft: "10px" }}>
          {selectedIndex !== -1 &&
          isItemHasPermission[selectedIndex]?.isPermission ? (
            <Outlet />
          ) : (
            <NoPermissionPage />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InventoryStore;
