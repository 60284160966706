/* eslint-disable no-return-assign */
import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, MenuItem, Menu } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchitemSales } from "../../../redux/actions";
import Daterange from "../../../components/DateRange";
import { AvenirBlackH4, AvenirBlackH2, TBold } from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import ReportsTable from "../../../components/ReportsTable";
import SingleSelect from "../../../components/SingleSelect";
import EmployeeDropDown from "../../../components/EmployeeDropDown";
import { downloadItemSales } from "../../../utils/excel";
import { downloadItemSalesPdfDocument } from "../../../components/PDFTableGenerator";

const ItemSales = () => {
  const dispatch = useDispatch();
  const itemSales = useSelector((state) => state.salesReportReducer.itemSales);
  const [table1, setTable1] = useState([]);
  const Context = useContext(MyContext);
  const [date, setDate] = useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD"),
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
  });
  const [ordertype] = useState([]);
  const [itemtypes, setItemTypes] = useState("all");
  const [resetDate, toggleResetDate] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [columns, setColumns] = useState([
    { field: "item_name", display: Context.langData.item_name, size: "18%" },
    { field: "quantity", display: Context.langData.quantity, size: "15%" },
    {
      field: "item_discount",
      display: Context.langData.item_discounts,
      size: "15%",
      isNumber: true,
    },
    {
      field: "total_tax",
      display: Context.langData.total_tax,
      size: "15%",
      isNumber: true,
    },
    {
      field: "total_price",
      display: Context.langData.total_price,
      size: "15%",
      isNumber: true,
    },
    {
      field: "final_price",
      display: Context.langData.final_price,
      size: "15%",
      isNumber: true,
    },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const itemsDropDownList = [
    {
      id: 2,
      name: Context.langData.all_items,
      key: "all",
    },
    {
      id: 1,
      name: Context.langData.sold_items,
      key: "sold",
    },
    {
      id: 0,
      name: Context.langData.unsold_items,
      key: "unsold",
    },
    {
      id: 3,
      name: Context.langData.custom_items,
      key: "custom",
    },
  ];
  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype, itemtypes, empData]);
  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate,
    };
    if (empData.length > 0) {
      obj = {
        ...obj,
        employee_id: empData.join(","),
      };
    }
    if (itemtypes !== "all") {
      obj = {
        ...obj,
        filter_by: itemtypes,
      };
    }
    dispatch(fetchitemSales(obj));
  };
  useEffect(() => {
    if (itemSales?.length > 0) {
      const items = [...itemSales];
      items.map((menu) => {
        let final_price = 0;
        let item_discount = 0;
        let total_tax = 0;
        let total_price = 0;
        menu.items.map((menuItem) => {
          final_price += menuItem.final_price;
          item_discount += menuItem.item_discount;
          total_tax += menuItem.total_tax;
          total_price += menuItem.total_price;
        });
        const totalResult = menu.items.reduce(
          (total, currentValue) => (total += currentValue.quantity),
          0
        );
        const obj = {
          final_price,
          item_discount,
          total_tax,
          total_price,
          item_name: "Total",
          quantity: totalResult,
        };
        menu.items.push(obj);
      });
      setTable1(items);
    } else {
      setTable1([]);
    }
  }, [itemSales]);

  const handleSort = (name, desc, index, catIndx) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], desc };
    setColumns(columnData);
    const catItems = table1[catIndx].items;
    const tableSortRemoveTotal = _.remove(catItems, { item_name: "Total" });
    let sortItemwiseSales;
    if (name === "item_name") {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortItemwiseSales = _.orderBy(catItems, [name], [desc ? "desc" : "asc"]);
    }
    const tableSortAddTotal = [...sortItemwiseSales, ...tableSortRemoveTotal];
    table1[catIndx].items = tableSortAddTotal;
    setTable1(table1);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadItemSales(table1);
    handleCloseMenu();
  };

  const downloadPDF = () => {
    downloadItemSalesPdfDocument(
      columns,
      table1,
      Context.langData.items_sales,
      date,
      "ItemSales"
    );
    handleCloseMenu();
  };
  const onClear = () => {
    setItemTypes("all");
    setEmpData([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  return (
    <Grid
      data-qaid='itemSales_mainContainer'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid data-qaid='itemSales_subContainer' container>
        <Grid
          data-qaid='itemSales_dateContainer'
          item
          lg={9}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid data-qaid='itemSales_dateGrid' item style={{}}>
            <AvenirBlackH4
              data-qaid='itemSales_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='itemSales_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid='itemSales_orderTypeGrid'
            item
            style={{ paddingLeft: 10 }}>
            <AvenirBlackH4
              data-qaid='itemSales_orderTypeLabel'
              label={Context.langData.item_types}
            />
            <SingleSelect
              data-qaid='alltrancation_ordertype_singleselect'
              list={itemsDropDownList}
              value={itemtypes}
              valueKey='key'
              displayKey='name'
              handleChange={(e) => {
                setItemTypes(e.target.value);
              }}
              border={false}
              height={56}
              width={220}
            />
          </Grid>
          <EmployeeDropDown
            selectedValue={empData}
            handleChange={setEmpData}
            lg={5}
          />
        </Grid>
        <Grid
          data-qaid='itemSales_circleCloseContainer'
          item
          lg={3}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}>
          <Grid
            data-qaid='itemSales_circleCloseGrid'
            onClick={onClear}
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 20,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose data-qaid='itemSales_circleCloseIcon' />
            <AvenirBlackH4
              data-qaid='itemSales_circleCloseLabel'
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='itemSales_downlodeButtonGrid'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='itemSales_downlodeButtonIcon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='itemSales_downlodeLabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        data-qaid='itemSales_reportsTableGrid'
        item
        lg={12}
        style={{ marginTop: 40 }}>
        {table1.length > 0 ? (
          table1.map((menu, index) => (
            <>
              <Grid
                data-qaid='itemSale_HeadingLabelGrid'
                container
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}>
                <Grid item>
                  <AvenirBlackH2
                    data-qaid='groups_HeadingLabel'
                    label={menu.menu_cat}
                    style={{
                      fontWeight: "bold",
                      marginLeft: 20,
                      marginBottom: 15,
                    }}
                  />
                </Grid>
              </Grid>
              <ReportsTable
                data-qaid='itemSales_reportsTable'
                handleSort={(name, desc, colIndex) =>
                  handleSort(name, desc, colIndex, index)
                }
                columns={columns}
                rows={menu.items}
              />
            </>
          ))
        ) : (
          <Grid
            item
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: 20,
            }}>
            <TBold
              label={Context.langData.no_results_available}
              style={{ fontSize: 32, color: "#051D33" }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ItemSales;
