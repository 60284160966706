import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import styles from "../../../../styles/index";
import {
  fetchDeliveryOptions,
  deleteDeliveryOptions,
} from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../../utils/text";
import {
  Delete as DeleteIcon,
  DisableDelete,
  PluseIcon as CirclePluse,
} from "../../../../components/SVG";
import {
  SnackBar,
  NoData,
  DataTable,
  Dialog as AlertDialog,
  TitleWrapper,
  MainBodyWrapper,
} from "../../../../components";
import CreateMore from "./CreateMore";
import EditMoreDrawer from "./EditMore";

const MoreList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: Context.langData.name,
      size: "50%",
      sort: true,
    },
    {
      field: "description",
      headerName: Context.langData.description,
      size: "50%",
      sort: false,
    },
  ]);
  const DeliveryOptionsData = useSelector(
    (state) => state.settingsReducer.deliveryOptions
  );
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [heading] = useState(Context.langData.delete_more);
  const [snackbar, setSnackbar] = useState(null);
  const [Meta] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const bodyMessage =
    selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} receipt labels?`
      : Context.langData.delete_more_body;
  useEffect(() => {
    const filterDeliveryOptionData = _.reverse(DeliveryOptionsData);
    setDeliveryOptions(filterDeliveryOptionData);
  }, [DeliveryOptionsData]);
  useEffect(() => {
    dispatch(fetchDeliveryOptions());
  }, []);
  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  const commanCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchDeliveryOptions());
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.more_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.more_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.more_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.more_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.more_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.more_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(
        deleteDeliveryOptions(JSON.stringify(selectedId), deleteCallback)
      );
    }
    setShowDialog(false);
  };
  const deletePrinter = (value) => {
    if (selectedId && selectedId.length > 0) {
      setShowDialog(value);
    }
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortComments = _.orderBy(
      deliveryOptions,
      [value],
      [sortValue ? "desc" : "asc"]
    );
    setDeliveryOptions(sortComments);
  };
  return (
    <MainBodyWrapper>
      <div data-qaid='morelist_div'>
        {deliveryOptions.length > 0 ? (
          <>
            <TitleWrapper
              mainTitle={Context.langData.settings}
              subTitle={Context.langData.delivery_options}>
              <Grid
                data-qaid='morelist_action_grid'
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='otype_delete_button'
                  style={{ color: "white", marginRight: 10 }}
                  variant='text'
                  id='cancel'
                  onClick={() => deletePrinter(true)}
                  disabled={selectedId?.length <= 0}>
                  {selectedId?.length <= 0 ? (
                    <DisableDelete
                      data-qaid='shifts_deleteicon'
                      width={16}
                      height={16}
                      style={{
                        marginRight: 5,
                      }}
                    />
                  ) : (
                    <DeleteIcon
                      data-qaid='shifts_deleteicon'
                      width={16}
                      height={16}
                      style={{
                        marginRight: 5,
                      }}
                    />
                  )}

                  <AvenirBlackH4
                    data-qaid='otype_delete_label'
                    label={Context.langData.delete}
                    style={{
                      color: selectedId?.length <= 0 ? "#C3C3C3" : "#004C60",
                    }}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='morelist_create_grid'
                  style={{ backgroundColor: "#FFB600", color: "white" }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleCreateDrawer(true)}>
                  <CirclePluse data-qaid='morelist_createicon' />
                  <AvenirBlackH4
                    data-qaid='morelist_create_Label'
                    label={Context.langData.create_deliveryOption}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
              </Grid>
            </TitleWrapper>

            <DataTable
              data-qaid='morelist_datatable'
              columns={columns}
              rows={deliveryOptions}
              refresh={openCreate}
              onCheckedIds={onCheckedIds}
              onClickAction={onClickAction}
              sortingOnClick={sortingOnClick}
            />
          </>
        ) : (
          <NoData
            page='delivery option'
            callback={() => toggleCreateDrawer(true)}
          />
        )}
        {openCreate && (
          <CreateMore
            data-qaid='morelist_createmore'
            open={openCreate}
            onClose={() => toggleCreateDrawer(false)}
            more={Meta}
            callback={createCallback}
          />
        )}
        {openEdit && (
          <EditMoreDrawer
            data-qaid='morelist_editmore'
            open={openEdit}
            row={row}
            more={Meta}
            onClose={() => toggleEditDrawer(false)}
            callback={editCallback}
          />
        )}
        {snackbar && (
          <SnackBar
            data-qaid='categories_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
        <AlertDialog
          data-qaid='morelist_alertdialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={heading}
          IsError={false}
          handleClose={handleClose}
        />
      </div>
    </MainBodyWrapper>
  );
};

export default MoreList;
