import moment from "moment";
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
} from "./workbook";

const downloadSalesReport = (
  salesSummary,
  paymentMethod,
  orderType,
  shiftsData,
  taxType
) => {
  const workbook = getWookBook();
  salesSummaryReport(salesSummary, workbook);
  paymentSalesReport(paymentMethod, workbook);
  ordertypeReport(orderType, workbook);
  shiftsReport(shiftsData, workbook);
  taxReport(taxType, workbook);
  downloadAs(
    workbook,
    `salessummary_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
const taxReport = (taxType, workbook) => {
  const ws = workbook.addWorksheet("Tax report");
  ws.addRow(["Tax type", "Total collected", "Taxable amount"]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 40;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  taxType?.data?.map((payment, index) => {
    ws.addRow([payment.type, payment.taxable_amt, payment.sales]);
    setNormalRowStyle(ws, index + 2);
  });
};
const shiftsReport = (ordertypes, workbook) => {
  const ws = workbook.addWorksheet("Shifts sales");
  ws.addRow(["Shifts", "Subtotal", "Discount", "Tax", "Transactions", "Total"]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 40;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(6).width = 20;
  ordertypes?.data?.map((payment, index) => {
    ws.addRow([
      payment.type,
      payment.subtotal,
      payment.discount,
      payment.taxes,
      payment.transactions,
      payment.sales,
    ]);
    setNormalRowStyle(ws, index + 2);
  });
};
const ordertypeReport = (ordertypes, workbook) => {
  const ws = workbook.addWorksheet("Order type sales");
  ws.addRow([
    "Order type",
    "Subtotal",
    "Discount",
    "Tax",
    "Transactions",
    "Total",
  ]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 40;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 20;
  ordertypes?.data?.map((payment, index) => {
    ws.addRow([
      payment.type,
      payment.subtotal,
      payment.discount,
      payment.taxes,
      payment.transactions,
      payment.sales,
    ]);
    setNormalRowStyle(ws, index + 2);
  });
};
const salesSummaryReport = (salesSummary, workbook) => {
  const ws = workbook.addWorksheet("Sales summary");
  salesSummary?.data?.map((summary) => {
    ws.addRow([summary.type, summary.sales]);
  });
  ws.addRow(["Grand Total", salesSummary.Total]);
  ws.getColumn(1).font = {
    color: { argb: "00000000" },
    size: 14,
    bold: true,
  };
  ws.getColumn(1).width = 30;
};
const paymentSalesReport = (paymentMethods, workbook) => {
  const ws = workbook.addWorksheet("Payment type sales");
  ws.addRow([
    "Payment method",
    "Subtotal",
    "Discount",
    "Tax",
    "Transactions",
    "Total",
    "Rounding",
  ]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 25;
  ws.getColumn(2).width = 15;
  ws.getColumn(3).width = 15;
  ws.getColumn(4).width = 15;
  ws.getColumn(5).width = 15;
  ws.getColumn(6).width = 15;
  ws.getColumn(7).width = 15;
  paymentMethods?.data?.map((payment, index) => {
    ws.addRow([
      payment.type,
      payment.subtotal,
      payment.discount,
      payment.taxes,
      payment.transactions,
      payment.sales,
      payment.rounding,
    ]);
    setNormalRowStyle(ws, index + 2);
  });
};
const downloadItemSales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Item Sales");
  let i = 0;
  ws.getColumn(1).width = 80;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(6).width = 20;
  data.map((menu) => {
    ws.addRow([menu.menu_cat]);
    i++;
    const row = ws.getRow(i);
    ws.mergeCells(i, 1, i, 6);
    menuStyle(row);
    ws.addRow([
      "Item name",
      "Quantity",
      "Item discounts",
      "Total tax",
      "Total price",
      "Final price",
    ]);
    i++;
    setHeaderStyle(ws, i);
    menu?.items?.map((item) => {
      ws.addRow([
        item.item_name,
        item.quantity,
        item.item_discount,
        item.total_tax,
        item.total_price,
        item.final_price,
      ]);
      i++;
    });
  });
  downloadAs(workbook, `itemsales_${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};
const menuStyle = (row) => {
  row?.eachCell((cell) => {
    cell.font = {
      color: { argb: "00000000" },
      size: 15,
      bold: true,
    };
  });
  row.height = 25;
  row.alignment = { vertical: "middle" };
};
const downloadDailySales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Daywise sales");
  ws.addRow([
    "Date",
    "Subtotal",
    "Discount",
    "Delivery",
    "Taxes",
    "Total",
    "Number of customers",
    "Avg bill",
  ]);
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 20;
  ws.getColumn(7).width = 20;
  setHeaderStyle(ws, 1);
  data?.map((d, i) => {
    ws.addRow([
      d.date?.trim(),
      d.total_price,
      d.total_discount,
      d.delivery_charges,
      d.total_tax,
      d.final_price,
      d.seats,
      d.avg_bill,
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `daywisesales_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
const downloadAlcoholSales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Alcohol Sales");
  ws.addRow([
    "Date",
    // "Customer name",
    "Price",
    "Order types",
    // "Delivery address",
    // "Delivery date",
    // "Delivered by",
  ]);
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 30;
  ws.getColumn(3).width = 20;
  // ws.getColumn(4).width = 40;
  // ws.getColumn(5).width = 20;
  // ws.getColumn(6).width = 20;
  setHeaderStyle(ws, 1);
  data?.map((d, i) => {
    ws.addRow([
      d.date,
      // d.customer_name,
      d.price,
      d.order_type,
      // d.delivery_address,
      // d.delivery_time,
      // d.delivered_by
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `alcoholsales_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
const downloadAddonSales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Alcohol Sales");
  ws.addRow(["Addon name", "Quantity", "Total sales"]);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  setHeaderStyle(ws, 1);
  data?.map((d, i) => {
    ws.addRow([d.addon_name, d.total_quantity, d.total_sales]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(workbook, `addonsales_${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};
const downloadEmpSales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Alcohol Sales");
  ws.addRow([
    "Employee name",
    "Item discounts",
    "Order discounts",
    "Total sales",
    "Tips collected",
    "Hours worked",
    // "Tables served"
  ]);
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 10;
  ws.getColumn(6).width = 10;
  setHeaderStyle(ws, 1);
  data?.map((d, i) => {
    ws.addRow([
      d.employee_name,
      d.employee_sales,
      d.employee_tips,
      d.employee_hours || 0,
      d.employee_tables_served,
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `employee_sales_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
const downloadGroupsData = (data) => {
  console.log(data);
};
const downloadPettyCashReport = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Petty cash");
  ws.addRow(["Category name", "Debits", "Credits", "Net debits"]);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  setHeaderStyle(ws, 1);
  data?.map((d, i) => {
    ws.addRow([d.cat_name, `$${d.debit}`, `$${d.credit}`, `$${d.net_debit}`]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `pettycash_sales_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
const downloadModifierSales = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Modifier Sales");
  let i = 0;
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 30;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  data?.map((menu) => {
    ws.addRow([menu.addon_cat]);
    i++;
    const row = ws.getRow(i);
    ws.mergeCells(i, 1, i, 4);
    menuStyle(row);
    ws.addRow(["Item name", "Modifier name", "Quantity", "Total sales"]);
    i++;
    setHeaderStyle(ws, i);
    menu.addons.map((item) => {
      ws.addRow([
        item.item_name,
        item.addon_name,
        item.total_quantity,
        item.total_sales,
      ]);
      i++;
    });
  });
  downloadAs(
    workbook,
    `addonDetails_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};
// const downloadContactTracing = (data) => {
//   const workbook = getWookBook()
//   const ws = workbook.addWorksheet("Visitor log");
//   let i = 0;
//   ws.getColumn(1).width = 80;
//   ws.getColumn(2).width = 20;
//   ws.getColumn(3).width = 20;
//   ws.getColumn(4).width = 20;
//   ws.getColumn(5).width = 20;
//   ws.getColumn(6).width = 20;
//   // ws.getColumn(6).width = 20;
//   data.map((menu, index) => {
//     ws.addRow([menu.menu_cat]);
//     i++;
//     const row = ws.getRow(i);
//     ws.mergeCells(i, 1, i, 6);
//     menuStyle(row);
//     ws.addRow(["Name", "Date", "Time", "Email", "Phone", "Server"]);
//     i++;
//     setHeaderStyle(ws, i)
//     menu.items.map((item) => {
//       ws.addRow([
//         item.name,
//         item.ts,
//         item.ts,
//         item.email,
//         item.phone,
//         item.server
//       ]);
//       i++;

//     })
//   })
//   downloadAs(workbook, `itemsales_${moment().format("yyyyMMDDHHmmss")}.xlsx`)
// }

const downloadContactTracing = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Visitor log");
  ws.addRow(["Name", "Date", "Time", "Email", "Phone", "Server"]);
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 40;
  ws.getColumn(3).width = 50;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(6).width = 20;
  setHeaderStyle(ws, 1);
  data?.map((item, i) => {
    ws.addRow([
      item.name,
      item.dateValue,
      item.timeValue,
      item.email,
      item.phone,
      item.server,
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(workbook, `visitorlog${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};
export {
  downloadSalesReport,
  downloadItemSales,
  downloadDailySales,
  downloadAlcoholSales,
  downloadAddonSales,
  downloadEmpSales,
  downloadGroupsData,
  downloadPettyCashReport,
  downloadContactTracing,
  downloadModifierSales,
};
