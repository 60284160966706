import * as React from "react";
// import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

const EmptyState = () => (
  <svg
    width='124'
    height='102'
    viewBox='0 0 124 102'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.00439453 9.25195H124V97.1438C124 99.5478 122.038 101.501 119.624 101.501H4.38022C1.96597 101.501 0.00439453 99.5478 0.00439453 97.1438V9.25195Z'
      fill='#F3FAFD'
    />
    <path
      d='M0.00439453 87.127V97.1391C0.00439453 99.5431 1.9614 101.496 4.38022 101.496H119.624C122.038 101.496 124 99.5476 124 97.1391V87.127H0.00439453Z'
      fill='#051D33'
    />
    <path
      d='M123.996 9.25195L6.10352e-05 9.25195V4.85827C6.10352e-05 2.45427 1.96162 0.501018 4.37588 0.501018L119.62 0.501018C122.034 0.501018 123.996 2.45427 123.996 4.85827V9.25195Z'
      fill='#051D33'
    />
    <path
      d='M6.50876 7.09939C7.74653 7.09939 8.74995 6.10388 8.74995 4.87586C8.74995 3.64785 7.74653 2.65234 6.50876 2.65234C5.27099 2.65234 4.26758 3.64785 4.26758 4.87586C4.26758 6.10388 5.27099 7.09939 6.50876 7.09939Z'
      fill='white'
    />
    <path
      d='M13.8847 7.09939C15.1225 7.09939 16.1259 6.10388 16.1259 4.87586C16.1259 3.64785 15.1225 2.65234 13.8847 2.65234C12.647 2.65234 11.6436 3.64785 11.6436 4.87586C11.6436 6.10388 12.647 7.09939 13.8847 7.09939Z'
      fill='white'
    />
    <path
      d='M21.2607 7.09939C22.4985 7.09939 23.5019 6.10388 23.5019 4.87586C23.5019 3.64785 22.4985 2.65234 21.2607 2.65234C20.0229 2.65234 19.0195 3.64785 19.0195 4.87586C19.0195 6.10388 20.0229 7.09939 21.2607 7.09939Z'
      fill='white'
    />
    <path
      d='M120.736 2.56929C120.451 2.2868 119.987 2.2868 119.698 2.56929L118.375 3.88154L117.052 2.56929C116.768 2.2868 116.304 2.2868 116.014 2.56929C115.73 2.85179 115.73 3.31198 116.014 3.59904L117.337 4.91128L116.014 6.22352C115.73 6.50602 115.73 6.96621 116.014 7.25326C116.299 7.53576 116.763 7.53576 117.052 7.25326L118.375 5.94102L119.698 7.25326C119.982 7.53576 120.446 7.53576 120.736 7.25326C121.02 6.97077 121.02 6.51057 120.736 6.22352L119.413 4.91128L120.736 3.59904C121.02 3.31654 121.02 2.85635 120.736 2.56929Z'
      fill='white'
    />
    <rect
      x='19.1357'
      y='15.4629'
      width='87.2593'
      height='12.9098'
      rx='6.45489'
      fill='#E6F4F9'
    />
    <rect
      x='26.0249'
      y='75.6797'
      width='73.4815'
      height='4.55639'
      rx='2.27819'
      fill='#E6F4F9'
    />
    <rect
      x='9.18506'
      y='41.5078'
      width='107.16'
      height='4.25263'
      rx='2.12632'
      fill='#C8E6F1'
    />
    <rect
      x='9.18506'
      y='50.0137'
      width='107.16'
      height='4.25263'
      rx='2.12632'
      fill='#C8E6F1'
    />
    <rect
      x='9.18506'
      y='58.5176'
      width='107.16'
      height='4.25263'
      rx='2.12632'
      fill='#C8E6F1'
    />
    <path
      d='M38.2717 69.3528C38.7814 71.7496 40.5582 73.6237 42.8307 74.1613C47.3401 69.1811 51.892 64.1411 56.4793 59.0564C56.9253 58.5636 57.3642 58.0708 57.8102 57.5854C58.1217 57.2345 58.4402 56.8836 58.7517 56.5401C59.1906 56.0473 59.6366 55.5545 60.0826 55.0692C60.9746 54.0761 61.8736 53.0831 62.7656 52.09L59.2119 48.3418C58.2703 49.2901 57.3288 50.2308 56.3873 51.1716C55.9201 51.642 55.4528 52.105 54.9927 52.5754C54.66 52.9039 54.3272 53.2399 54.0016 53.5684C53.5344 54.0388 53.0671 54.5017 52.607 54.9721C47.779 59.8105 43.0006 64.6116 38.2858 69.3678L38.2717 69.3528Z'
      fill='#051D33'
    />
    <path
      d='M79.9039 31.7506C78.7265 30.6381 77.3553 29.7744 75.8293 29.1888C74.3497 28.6252 72.7926 28.3397 71.1891 28.3397C69.5856 28.3397 68.0285 28.6252 66.5489 29.1888C65.0228 29.7744 63.6517 30.6381 62.4742 31.7506C61.2968 32.8632 60.3827 34.1588 59.7629 35.6007C59.1665 36.9987 58.8643 38.47 58.8643 39.9851C58.8643 41.5002 59.1665 42.9715 59.7629 44.3695C60.3827 45.8114 61.2968 47.107 62.4742 48.2195C63.6517 49.3321 65.0228 50.1958 66.5489 50.7814C68.0285 51.345 69.5856 51.6304 71.1891 51.6304C72.7926 51.6304 74.3497 51.345 75.8293 50.7814C77.3553 50.1958 78.7265 49.3321 79.9039 48.2195C81.0814 47.107 81.9955 45.8114 82.6152 44.3695C83.2117 42.9715 83.5138 41.5002 83.5138 39.9851C83.5138 38.47 83.2117 36.9987 82.6152 35.6007C81.9955 34.1588 81.0814 32.8632 79.9039 31.7506ZM82.5533 29.2474C88.828 35.1762 88.828 44.794 82.5533 50.7301C76.2786 56.6589 66.0996 56.6589 59.8172 50.7301C53.5425 44.8013 53.5425 35.1835 59.8172 29.2474C66.0918 23.3186 76.2708 23.3186 82.5533 29.2474Z'
      fill='#051D33'
    />
  </svg>
);

export default EmptyState;
