import * as React from "react";

const MenuIconTwoLines = ({
  width = "24",
  height = "25",
  color = "#007191",
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.625 7.83203C2.21079 7.83203 1.875 8.16782 1.875 8.58203C1.875 8.99624 2.21079 9.33203 2.625 9.33203H21.375C21.7892 9.33203 22.125 8.99624 22.125 8.58203C22.125 8.16782 21.7892 7.83203 21.375 7.83203H2.625ZM2.625 14.7982C2.21079 14.7982 1.875 15.134 1.875 15.5482C1.875 15.9624 2.21079 16.2982 2.625 16.2982H21.375C21.7892 16.2982 22.125 15.9624 22.125 15.5482C22.125 15.134 21.7892 14.7982 21.375 14.7982H2.625Z'
      fill={color}
    />
  </svg>
);

export default MenuIconTwoLines;
