import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchMore } from "../../../../redux/actions";
import MoreList from "./moreList";

const More = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMore());
  }, []);
  return (
    <div data-qaid='more_index_morelist_div'>
      <MoreList data-qaid='more_index_morelist_morelist' />
    </div>
  );
};

export default More;
