import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@material-ui/core";

const OrderTypes = () => (
  <Grid container direction='row' style={{ borderTop: "1px solid #EEEEEE" }}>
    <Grid item xs={12} lg={12} xl={12}>
      <Outlet />
    </Grid>
  </Grid>
);

export default OrderTypes;
