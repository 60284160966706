import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTermial, fetchAllCategories } from "../../../../redux/actions";
import TerminalsList from "./terminalsList";

const Terminals = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTermial());
    dispatch(fetchAllCategories());
  }, []);
  return (
    <div>
      <TerminalsList data-qaid='taxes-TerminalsList' />
    </div>
  );
};

export default Terminals;
